/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { MediaItemType } from '@SLR/media-service-sdk';
import {
  ExtendedMediaItem,
  SLRVideoPlayer,
  isEmptyOrNull,
  useVideoPlayer,
  useWindowSize
} from '@SLR/shared-library';
import { CarouselArrow } from 'components';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Slider, { Settings } from 'react-slick';
import { GalleryImage, VideoConfirmation } from '.';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './GalleryCarousel.scss';

const defaultSettings: Settings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '60px',
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '30px'
      }
    }
  ]
};

export type GalleryCarouselProps = {
  entryName?: string | null;
  mediaItems: ExtendedMediaItem[];
  selectedItem?: number;
  settings?: Settings;
  itemClassName?: string;
  onImageClick?: (selectedItem: number) => void;
};

export const GalleryCarousel: FC<GalleryCarouselProps> = ({
  entryName,
  mediaItems,
  selectedItem,
  settings = defaultSettings,
  itemClassName = 'default',
  onImageClick
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.gallery'
  });

  const { width } = useWindowSize();

  const slider = useRef<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>();

  useEffect(() => {
    if (slider?.current) {
      /**
       * Go to selected item if not undefined
       * In case window width is 1000px or wider and there are more than two elements react slick shows the last element as first item,
       * thus we need to shift the items to keep the order
       */
      const position = !isEmptyOrNull(selectedItem)
        ? selectedItem
        : width > 1000 && mediaItems.length > 2
          ? 1
          : 0;
      slider.current.slickGoTo(position, true);
      setCurrentSlide(position);
    }
  }, [mediaItems.length, selectedItem, width]);

  const { pause } = useVideoPlayer();

  const handleChange = useCallback(
    (slide: number) => {
      pause();
      setCurrentSlide(slide);
    },
    [pause]
  );

  return (
    <Container fluid className="gallery-carousel p-0">
      <Slider
        ref={slider}
        infinite={mediaItems.length > 1}
        arrows={mediaItems.length > 1}
        prevArrow={
          <CarouselArrow
            direction="left"
            componentClassName="gallery-button"
            iconTitle={t('left')}
          />
        }
        nextArrow={
          <CarouselArrow
            direction="right"
            componentClassName="gallery-button"
            iconTitle={t('right')}
          />
        }
        swipe={settings.slidesToShow === 1 || width < 1000}
        afterChange={handleChange}
        {...settings}
      >
        {mediaItems?.map(({ id, url, mediaType, altText, embedded }, index) =>
          mediaType.mediaItemType === MediaItemType.Image ? (
            <Button
              variant="link"
              className={`p-0 m-0 gallery-div-${itemClassName}`}
              key={id}
              onClick={() => {
                pause();
                if (onImageClick) {
                  onImageClick(index);
                }
              }}
            >
              <GalleryImage
                alt={
                  altText ??
                  t('pictureAlt', { count: index, solution: entryName })
                }
                url={url}
                disableZoom={itemClassName === 'default'}
                isActive={currentSlide === index}
                className={`gallery-item-${itemClassName} rounded`}
              />
            </Button>
          ) : (
            <div
              className={`p-0 m-0 gallery-div-${itemClassName} btn btn-link text-center`}
              key={id}
            >
              <SLRVideoPlayer
                id={id}
                url={embedded ?? url}
                className={`gallery-item-${itemClassName} rounded`}
                confirmationView={
                  <VideoConfirmation
                    id={id}
                    url={embedded ?? url}
                    className={`gallery-item-${itemClassName} rounded`}
                  />
                }
              />
            </div>
          )
        )}
      </Slider>
    </Container>
  );
};
