/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { showErrorToast } from '@SLR/shared-library';
import { useMutation } from '@tanstack/react-query';
import { queryClient, queryKeys } from 'configs';
import { useExperienceReportsApi } from 'services';

export const useUpdateExperienceReport = () => {
  const { updateExperienceReport } = useExperienceReportsApi();

  return useMutation({
    mutationFn: updateExperienceReport,
    onSuccess: (_, { experienceReportDto }) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getEntry, experienceReportDto.entryId]
      });
    },
    onError: () => showErrorToast('toasts.experienceReportUpdate.error')
  });
};
