/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  EntriesApi,
  GetPublishedEntryRequest,
  SendContactMessageRequest
} from '@SLR/marketplaceService-sdk';
import { handleError } from '@SLR/shared-library';
import { useCallback, useMemo } from 'react';
import { useGetMarketplaceServiceApiConfiguration } from './marketplace.service';

export const useEntriesApi = () => {
  const configuration = useGetMarketplaceServiceApiConfiguration();
  const apiInstance = useMemo(
    () => new EntriesApi(configuration),
    [configuration]
  );

  const getPublishedEntry = useCallback(
    (requestParameters: GetPublishedEntryRequest) =>
      apiInstance.getPublishedEntry(requestParameters).catch(handleError),
    [apiInstance]
  );

  const getPublishedEntries = useCallback(
    () => apiInstance.getPublishedEntries().catch(handleError),
    [apiInstance]
  );

  const sendContactMessage = useCallback(
    (requestParameters: SendContactMessageRequest) =>
      apiInstance.sendContactMessage(requestParameters).catch(handleError),
    [apiInstance]
  );

  return { getPublishedEntry, getPublishedEntries, sendContactMessage };
};
