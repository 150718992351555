/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRLegalRouting, SLRProtectedRoute } from '@SLR/shared-library';
import {
  MARKETPLACE_ENTRY_DETAILS_PATH,
  MARKETPLACE_MANAGEMENT_CREATE_ENTRY_PATH,
  MARKETPLACE_MANAGEMENT_EDIT_ENTRY_PATH,
  MARKETPLACE_MANAGEMENT_PATH,
  MARKETPLACE_PATH,
  MARKETPLACE_REVIEW_EDIT_ENTRY_PATH,
  MARKETPLACE_REVIEW_ENTRY_DETAILS_PATH,
  MARKETPLACE_REVIEW_PATH,
  legalRouts
} from 'configs';
import { useMarketplace, useOrganization } from 'providers';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  EntryCreateUpdate,
  EntryDetail,
  EntryReview,
  EntryReviewEdit,
  Marketplace
} from '.';

export const MarketplaceRouting: FC = () => {
  const { hasAccessByRole } = useMarketplace();
  const { isSolutionProvider } = useOrganization();

  return (
    <Routes>
      <Route index element={<Marketplace />} />
      <Route path={MARKETPLACE_PATH} element={<Marketplace />} />
      <Route path={MARKETPLACE_ENTRY_DETAILS_PATH} element={<EntryDetail />} />

      <Route element={<SLRProtectedRoute condition={hasAccessByRole} />}>
        <Route path={MARKETPLACE_REVIEW_PATH} element={<Marketplace />} />
        <Route
          path={MARKETPLACE_REVIEW_ENTRY_DETAILS_PATH}
          element={<EntryReview />}
        />
        <Route
          path={MARKETPLACE_REVIEW_EDIT_ENTRY_PATH}
          element={<EntryReviewEdit />}
        />
      </Route>

      <Route element={<SLRProtectedRoute condition={isSolutionProvider} />}>
        <Route path={MARKETPLACE_MANAGEMENT_PATH} element={<Marketplace />} />
        <Route
          path={MARKETPLACE_MANAGEMENT_CREATE_ENTRY_PATH}
          element={<EntryCreateUpdate />}
        />
        <Route
          path={MARKETPLACE_MANAGEMENT_EDIT_ENTRY_PATH}
          element={<EntryCreateUpdate />}
        />
      </Route>

      <Route
        path="*"
        element={
          <SLRLegalRouting legals={legalRouts} redirect={MARKETPLACE_PATH} />
        }
      />
    </Routes>
  );
};
