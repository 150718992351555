/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  EntryDto,
  EntryDtoEndDevicesEnum,
  EntryDtoTargetGroupsEnum
} from '@SLR/marketplaceService-sdk';
import { getIcon, hasValue } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMarketplace } from 'providers';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './Target.scss';

const getTargetIcon = (target: string) => {
  switch (target) {
    case EntryDtoEndDevicesEnum.DesktopApp:
      return getIcon('fal', 'desktop');
    case EntryDtoEndDevicesEnum.MobileApp:
      return getIcon('fal', 'mobile-notch');
    case EntryDtoEndDevicesEnum.WebApp:
      return getIcon('fal', 'browser');
    case EntryDtoTargetGroupsEnum.Verwaltungen:
      return getIcon('fal', 'landmark');
    case EntryDtoTargetGroupsEnum.Unternehmen:
      return getIcon('fal', 'building');
    case EntryDtoTargetGroupsEnum.Vereine:
      return getIcon('fal', 'users');
    case EntryDtoTargetGroupsEnum.Brgerinnen:
      return getIcon('fal', 'user-vneck-hair-long');
    case EntryDtoTargetGroupsEnum.Bildungseinrichtungen:
      return getIcon('fal', 'graduation-cap');
    case EntryDtoTargetGroupsEnum.Jugendliche:
      return getIcon('fal', 'children');
    default:
      break;
  }
};

const getTargetIconView = (value: string) => {
  const icon = getTargetIcon(value);

  return (
    <>
      {icon !== undefined && (
        <FontAwesomeIcon icon={icon} size="1x" width={20} />
      )}
      {value}
    </>
  );
};

export type TargetValues = {
  title: string;
  areMandatory?: boolean;
  areClickable?: boolean;
  onClick?: (id: number) => void;
  values?: string[] | null;
};

export type TargetProps = {
  entry?: Nullable<EntryDto>;
};

export const Target: FC<TargetProps> = ({ entry }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.target'
  });

  const { isReviewContext, isManagementContext, getMarketplacePath } =
    useMarketplace();

  const targets: TargetValues[] = useMemo(
    () => [
      {
        title: t('targetGroups'),
        values: entry?.targetGroups,
        areMandatory: true
      },
      {
        title: t('endDevices'),
        values: entry?.endDevices
      },
      {
        title: t('categories'),
        areClickable: !(isReviewContext || isManagementContext),
        values: entry?.categories,
        areMandatory: true
      }
    ],
    [
      entry?.categories,
      entry?.endDevices,
      entry?.targetGroups,
      isReviewContext,
      isManagementContext,
      t
    ]
  );

  return (
    <div className="targets">
      {targets?.map(
        (target) =>
          (hasValue(target.values) || target.areMandatory) && (
            <div key={target.title} className="target">
              <h5 className="target-title">{target.title}</h5>
              <ul className="target-values list-unstyled fs-6">
                {hasValue(target.values) ? (
                  target.values?.map((value) => (
                    <li
                      key={value}
                      className="d-flex gap-1 align-items-center target-value "
                    >
                      {target.areClickable ? (
                        <Link
                          className="p-0 text-decoration-underline"
                          to={getMarketplacePath(value)}
                        >
                          {getTargetIconView(value)}
                        </Link>
                      ) : (
                        getTargetIconView(value)
                      )}
                    </li>
                  ))
                ) : (
                  <li className="d-flex fw-bold gap-1 align-items-center">-</li>
                )}
              </ul>
            </div>
          )
      )}
    </div>
  );
};
