/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  CreateExperienceReportRequest,
  DeleteExperienceReportRequest,
  ExperienceReportsApi,
  HTTPRequestInit,
  RequestOpts,
  UpdateExperienceReportRequest
} from '@SLR/marketplaceService-sdk';
import { handleError } from '@SLR/shared-library';
import { useCallback, useMemo } from 'react';
import { useGetMarketplaceServiceApiConfiguration } from './marketplace.service';

/**
 * Forces the request to ignore "readonly" for ids of experience report and keep it in the request
 */
const forceKeepArrayObjectId =
  (requestParameters: UpdateExperienceReportRequest) =>
  async (requestContext: { init: HTTPRequestInit; context: RequestOpts }) => {
    const { init } = await requestContext;
    // force entry update to keep the id as typescript sdk will omit it because readonly
    init.body = requestParameters.experienceReportDto;
    return init;
  };

export const useExperienceReportsApi = () => {
  const configuration = useGetMarketplaceServiceApiConfiguration();
  const apiInstance = useMemo(
    () => new ExperienceReportsApi(configuration),
    [configuration]
  );

  const createExperienceReport = useCallback(
    (requestParameters: CreateExperienceReportRequest) =>
      apiInstance.createExperienceReport(requestParameters).catch(handleError),
    [apiInstance]
  );

  const updateExperienceReport = useCallback(
    (requestParameters: UpdateExperienceReportRequest) =>
      apiInstance
        .updateExperienceReport(
          requestParameters,
          forceKeepArrayObjectId(requestParameters)
        )
        .catch(handleError),
    [apiInstance]
  );

  const deleteExperienceReport = useCallback(
    (requestParameters: DeleteExperienceReportRequest) =>
      apiInstance.deleteExperienceReport(requestParameters).catch(handleError),
    [apiInstance]
  );

  return {
    createExperienceReport,
    updateExperienceReport,
    deleteExperienceReport
  };
};
