/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { useMarketplace } from 'providers';
import { FC } from 'react';
import { Badge, Card, Col, Placeholder, Row } from 'react-bootstrap';

import './EntryItem.scss';
/**
 * Card loading view using the same skeleton and styles as an marketplace entry
 * @returns Loading Skeleton
 */

export const EntryLoader: FC = () => {
  const { isManagementContext, isReviewContext } = useMarketplace();

  const loader = (
    <Col className="py-3 entry">
      <Card className="entry-card">
        <Placeholder
          as={Card.Img}
          variant="top"
          className="entry-image"
          animation="wave"
        />

        <Placeholder
          as={Card.Body}
          className="entry-values px-4 pb-2"
          animation="wave"
        >
          <Row className="justify-content-end">
            <Col xs="auto">
              <Badge className="entry-badge">
                <Placeholder xs={12} bg="primary" className="placeholder" />
              </Badge>
            </Col>
          </Row>
          <Row className="entry-name">
            <Col>
              <Placeholder xs={4} />
            </Col>
          </Row>
          <Row className="entry-organization-name">
            <Col>
              <Placeholder xs={4} />
            </Col>
          </Row>
          <Row className="entry-description pt-3 ">
            <Col>
              {!(isManagementContext || isReviewContext) && (
                <>
                  <Placeholder xs={10} bg="neutral" />
                  <Placeholder xs={10} bg="neutral" />
                </>
              )}
              <Placeholder xs={8} bg="neutral" />
            </Col>
          </Row>
        </Placeholder>
        {!(isManagementContext || isReviewContext) && (
          <Placeholder
            as={Card.Footer}
            className="entry-price px-4 pb-3"
            animation="wave"
          >
            <Placeholder xs={4} bg="neutral" />
          </Placeholder>
        )}
      </Card>
    </Col>
  );

  return (
    <>
      <div>{loader}</div>
      <div className="d-none d-md-inline">{loader}</div>
      <div className="d-none d-xl-inline">{loader}</div>
    </>
  );
};
