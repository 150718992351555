/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { useAuth } from '@SLR/shared-library';
import { isDemo, isProd } from 'configs';
import { useMarketplace, useOrganization } from 'providers';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo
} from 'react';

export type AccessContextValues = {
  isPublicAccess?: boolean;
  isDemoAccess?: boolean;
  isProdAccess?: boolean;
};

const defaultAccessValues: AccessContextValues = {
  isPublicAccess: false,
  isDemoAccess: false,
  isProdAccess: false
};

const AccessContext = createContext<AccessContextValues>(defaultAccessValues);

export const useAccess = () => useContext(AccessContext);

export const AccessProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { hasAccessByRole } = useMarketplace();
  const { hasAccessByOrganization } = useOrganization();

  const isPublicAccess = useMemo(
    () =>
      !isDemo &&
      (!isAuthenticated ||
        !(isAuthenticated && (hasAccessByRole || hasAccessByOrganization))),
    [hasAccessByOrganization, hasAccessByRole, isAuthenticated]
  );

  const accessContextValues: AccessContextValues = useMemo(
    () => ({
      isPublicAccess,
      isDemoAccess: isDemo,
      isProdAccess: isProd
    }),
    [isPublicAccess]
  );

  return (
    <AccessContext.Provider value={accessContextValues}>
      {children}
    </AccessContext.Provider>
  );
};
