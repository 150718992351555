/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { marketplace } from './marketplace';
import { toasts } from './toasts';

export const de = {
  appName: 'DEUTSCHLAND.DIGITAL Marktplatz',
  navBar: {
    profile: 'Konto verwalten',
    switchOrganization: 'Organisation wechseln',
    toManagement: 'Zur Verwaltung',
    toMarketplace: 'Zum Marktplatz',
    toReview: 'Zum Review',
    for: 'für {{organization}}',
    login: 'Anmelden',
    logout: 'Abmelden'
  },
  marketplace: {
    ...marketplace
  },
  toasts: {
    ...toasts
  },
  form: {
    mediaItem: {
      dropZone: {
        error: { fileSize: 'Fehler: Bild zu groß (max. 10 MB)' },
        placeholder: 'Bilder hierher ziehen oder <0>durchsuchen</0>',
        restrictions: 'Max. 10 MB pro Bild, Formate: JPG, PNG, WebP'
      },
      url: {
        title: 'URL zu Youtube oder Vimeo-Video',
        placeholder: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        addButton: 'Taste zum Hinzufügen eines Youtube- oder Vimeo-Video URL'
      },
      item: {
        error: {
          upload: 'Fehler: Übertragung fehlgeschlagen',
          unsupported: 'Fehler: Die URL wird nicht unterstützt'
        },
        repeatUploadButton: 'Bild erneut hochladen Taste',
        deleteButton: 'Lösch-Taste',
        cancelUploadButton: 'Hochladen abbrechen Taste',
        dragButton: 'Zieh-Taste'
      },
      logo: {
        deletionConfirmationTitle: 'Bild entfernen?',
        deletionConfirmationQuestion: 'Soll das Bild gelöscht werden?',
        acceptDeletionConfirmation: 'Ja, entfernen!',
        declineDeletionConfirmation: 'Nein',
        discardChanges: 'Verwerfen',
        acceptChanges: 'Übernehmen',
        tooLargeFile: {
          title: 'Bild zu groß (max. 10 MB)',
          content:
            'Das ausgewählte Bild ist leider zu groß. Bitte wähle ein Bild mit maximal 10 MB aus.'
        },
        invalidFileType: {
          title: 'Dateiformat nicht unterstützt',
          content:
            'Leider unterstützen wir das Format der ausgewählten Datei nicht. Bitte wähle ein Bild im Format “jpg”, “jpeg” oder “png”.'
        },
        uploadLogoAlt: 'Rundes Bild einer Kamera',
        defaultAlt: 'Rundes Bild',
        uploadButtonLabel: 'Taste zur Auswahl eines neuen Bildes zum Hochladen',
        deleteButtonLabel: 'Lösch-Taste',
        rotateButtonLabel: 'Dreh-Taste'
      }
    },
    counter: '{{count}} / {{maxCount}} Zeichen',
    maxCharLengthError:
      'Maximale Zeichenlänge überschritten. Bitte kürze den Text.'
  },
  accessibility: {
    appURLLabel: 'Zurück zur Übersicht',
    navigateToLabel: 'Navigiere zur URL:',
    navigateBackLabel: 'Zurück zur vorherigen Seite',
    fallbackImageAlt:
      'Platzhalter Bild (Originalbild konnte nicht geladen werden)',
    profilePictureAlt: 'Profilbild',
    accordion: {
      deleteButtonLabel: 'Lösch-Taste',
      dragButtonLabel: 'Ziehen-Taste'
    }
  },
  navigation: {
    preventOrganizationChange: {
      title: 'Organization wechseln?',
      content:
        'Du wirst zur Übersicht weitergeleitet.\n Nicht gespeicherte Angaben gehen hierbei verloren.',
      cancel: 'Abbrechen',
      confirm: 'Organization wechseln'
    }
  },
  error: {
    title: 'Hoppla, etwas lief schief!',
    noResponse: 'Der Server reagiert nicht.\nBitte versuch es später erneut.',
    noAccess:
      'Du hast leider nicht die Rechte, um auf den DEUTSCHLAND.DIGITAL Marktplatz zuzugreifen. Besuche unsere Webseite und erfahre, wie du ein Teil von DEUTSCHLAND.DIGITAL wirst:',
    options:
      'Wir schauen uns das Problem umgehend an. Falls du Hilfe benötigst oder Fragen hast, wende dich gerne ans <0>DEUTSCHLAND.DIGITAL Support Team</0>!',
    toHome: 'Zur Startseite',
    toLandingPage: 'Zu DEUTSCHLAND.DIGITAL'
  },
  paywall: {
    title: {
      discoverMore: 'Mehr entdecken?',
      becomePart: 'Werdet Teil von DEUTSCHLAND.<wbr>DIGITAL!'
    },
    municipality: {
      title: 'Als Landkreis & Kommune',
      description:
        'Informiert euch über digitale Lösungen, vergleicht Angebote und tretet direkt mit Anbietern in Kontakt. Registriert euch jetzt und gestaltet die Zukunft der Daseinsvorsorge in eurer Region!'
    },
    solutionProvider: {
      title: 'Als Anbieter',
      description:
        'Präsentiert eure digitale Lösungen auf unserem Marktplatz und erreicht damit gezielt Entscheidungsträger von Kommunen und Landkreisen. Klingt interessant? Jetzt Zugang anfragen!'
    },
    learnMore: 'Mehr erfahren',
    alreadyMember: {
      description:
        'Bereits Mitglied einer Organisation mit Zugang zum Marktplatz?',
      login: '<0>Melde dich mit einem bestehenden Konto an!</0>'
    }
  },
  footer: {
    links: {
      title: 'Nützliche Links',
      privacyPolicy: 'Datenschutzhinweise',
      termsOfUse: 'Ergänzende Geschäftsbedingungen',
      imprint: 'Impressum'
    },
    help: {
      title: 'Fragen & Hilfe',
      contactUs: 'Einfach das Kontaktformular verwenden.',
      lookingForward: 'Wir freuen uns schon!',
      contact: 'Kontakt',
      support: 'Support',
      faq: 'FAQ'
    },
    project: {
      title: 'Das Projekt',
      description:
        '“DEUTSCHLAND.DIGITAL Marktplatz” wird vom Fraunhofer IESE entwickelt und betrieben. Das Fraunhofer IESE gehört zu den weltweit führenden Forschungseinrichtungen auf dem Gebiet der Software- und Systementwicklungsmethoden.',
      slr: 'Es ist Teil des Projektes <0>Smarte.Land.Regionen</0>. Mit dem Modellvorhaben unterstützt das Bundesministerium für Ernährung und Landwirtschaft (BMEL) sieben einzelne Landkreise bei der Entwicklung und Umsetzung digitaler Lösungen im ländlichen Raum.'
    },
    bmel: 'Förderhinweis Logo'
  }
};
