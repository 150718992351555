/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDtoStatusEnum } from '@SLR/marketplaceService-sdk';
import {
  SLRLabelIcon,
  SLRLoadingButton,
  SLRPrompt,
  SLRPromptLabels,
  SLRPromptProps,
  SLRTextEditor,
  getIcon,
  isEmptyOrNull
} from '@SLR/shared-library';
import { IconProp, RotateProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { LiveBadge } from 'components';
import { MARKETPLACE_REVIEW_PATH } from 'configs';
import {
  useApproveEntry,
  useRejectEntry,
  useRemoveEntry,
  useWithdrawEntry
} from 'hooks';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getBadgeInfo } from 'utils';
import { ObjectSchema, object, string } from 'yup';

import './ReviewActions.scss';

export type ReviewComment = { comment: string };
export const REVIEW_COMMENT_MAX_CHAR_LENGTH = 5000;

export const ReviewCommentSchema: ObjectSchema<ReviewComment> = object().shape({
  comment: string()
    .required('marketplace.entry.review.input.error')
    .max(REVIEW_COMMENT_MAX_CHAR_LENGTH, 'form.maxCharLengthError')
    .test(
      'isEmpty',
      'marketplace.entry.review.input.error',
      (value) => !isEmptyOrNull(value)
    )
    .default(null)
});

export const ReviewCommentType = {
  Approve: 'approve',
  Reject: 'reject',
  Withdraw: 'withdraw',
  Remove: 'remove'
} as const;

export type ReviewCommentType =
  (typeof ReviewCommentType)[keyof typeof ReviewCommentType];

export type ReviewActionsProps = {
  id?: string;
  submittedAt?: number | null;
  publishedAt?: number | null;
  status?: EntryDtoStatusEnum | null;
};

export const ReviewActions: FC<ReviewActionsProps> = ({
  id,
  submittedAt,
  publishedAt,
  status
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.review'
  });

  const navigate = useNavigate();
  const { state } = useLocation();

  const { mutate: approveEntry, isPending: isApproveEntryLoading } =
    useApproveEntry();
  const { mutate: rejectEntry, isPending: isRejectEntryLoading } =
    useRejectEntry();
  const { mutate: withdrawEntry, isPending: isWithdrawEntryLoading } =
    useWithdrawEntry();
  const { mutate: removeEntry, isPending: isRemoveEntryLoading } =
    useRemoveEntry();

  const [successPromptProps, setSuccessPromptProps] =
    useState<SLRPromptProps | null>(null);
  const handleHideSuccessPrompt = () => setSuccessPromptProps(null);

  const form = useForm<ReviewComment>({
    mode: 'onChange',
    resolver: yupResolver(ReviewCommentSchema)
  });

  const [reviewCommentPromptProps, setReviewCommentPromptProps] =
    useState<SLRPromptProps | null>(null);
  const [reviewCommentType, setReviewCommentType] = useState<ReviewCommentType>(
    ReviewCommentType.Approve
  );
  const handleHideReviewCommentPrompt = useCallback(() => {
    form.clearErrors();
    form.reset();
    setReviewCommentPromptProps(null);
  }, [form]);

  const handleNavigate = useCallback(() => {
    handleHideSuccessPrompt();
    handleHideReviewCommentPrompt();
    navigate(state?.previousPath ?? MARKETPLACE_REVIEW_PATH, {
      replace: true
    });
  }, [handleHideReviewCommentPrompt, navigate, state?.previousPath]);

  const showSuccessPrompt = useCallback(
    (
      labels: SLRPromptLabels,
      icon: { symbol: IconProp; rotation?: RotateProp },
      shouldLeavePage?: boolean
    ) =>
      setSuccessPromptProps({
        type: 'success',
        labels,
        icon,
        onConfirm: () =>
          shouldLeavePage ? handleNavigate() : handleHideSuccessPrompt(),
        onCancel: () =>
          shouldLeavePage ? handleNavigate() : handleHideSuccessPrompt()
      }),
    [handleNavigate]
  );

  const handleApproveEntry = useCallback(() => {
    setReviewCommentType(ReviewCommentType.Approve);
    setReviewCommentPromptProps({
      labels: t('prompts.approve.confirm', {
        returnObjects: true
      }),
      isLoading: isApproveEntryLoading,
      confirmButtonVariant: 'primary',
      onConfirm: () => {
        const comment = form.getValues('comment');
        if (!isEmptyOrNull(id)) {
          approveEntry(
            { id, body: comment },
            {
              onSuccess: () =>
                showSuccessPrompt(
                  t('prompts.approve.success', {
                    returnObjects: true
                  }) as SLRPromptLabels,
                  { symbol: getIcon('fat', 'check-circle') }
                )
            }
          );
          handleHideReviewCommentPrompt();
        }
      },
      onCancel: handleHideReviewCommentPrompt
    });
  }, [
    approveEntry,
    form,
    handleHideReviewCommentPrompt,
    id,
    isApproveEntryLoading,
    showSuccessPrompt,
    t
  ]);

  const handleRejectEntry = useCallback(() => {
    setReviewCommentType(ReviewCommentType.Reject);
    setReviewCommentPromptProps({
      labels: t('prompts.reject.confirm', {
        returnObjects: true
      }),
      isLoading: isRejectEntryLoading,
      confirmButtonVariant: 'danger',
      onConfirm: () => {
        const comment = form.getValues('comment');
        if (!isEmptyOrNull(id) && !isEmptyOrNull(comment)) {
          rejectEntry(
            { id, body: comment },
            {
              onSuccess: () =>
                showSuccessPrompt(
                  t('prompts.reject.success', {
                    returnObjects: true
                  }) as SLRPromptLabels,
                  { symbol: getIcon('fat', 'check-circle') }
                )
            }
          );
          handleHideReviewCommentPrompt();
        }
      },
      onCancel: handleHideReviewCommentPrompt
    });
  }, [
    form,
    handleHideReviewCommentPrompt,
    id,
    isRejectEntryLoading,
    rejectEntry,
    showSuccessPrompt,
    t
  ]);

  const handleWithdrawEntry = useCallback(() => {
    setReviewCommentType(ReviewCommentType.Withdraw);
    setReviewCommentPromptProps({
      labels: t('prompts.withdraw.confirm', {
        returnObjects: true
      }),
      isLoading: isWithdrawEntryLoading,
      confirmButtonVariant: 'danger',
      onConfirm: () => {
        const comment = form.getValues('comment');
        if (!isEmptyOrNull(id) && !isEmptyOrNull(comment)) {
          withdrawEntry(
            { id, body: comment },
            {
              onSuccess: () =>
                showSuccessPrompt(
                  t('prompts.withdraw.success', {
                    returnObjects: true
                  }) as SLRPromptLabels,
                  { symbol: getIcon('fat', 'check-circle') }
                )
            }
          );
          handleHideReviewCommentPrompt();
        }
      },
      onCancel: handleHideReviewCommentPrompt
    });
  }, [
    form,
    handleHideReviewCommentPrompt,
    id,
    isWithdrawEntryLoading,
    showSuccessPrompt,
    t,
    withdrawEntry
  ]);

  const handleRemoveEntry = useCallback(() => {
    setReviewCommentType(ReviewCommentType.Remove);
    setReviewCommentPromptProps({
      labels: t('prompts.remove.confirm', {
        returnObjects: true
      }),
      isLoading: isRemoveEntryLoading,
      confirmButtonVariant: 'danger',
      onConfirm: () => {
        const comment = form.getValues('comment');
        if (!isEmptyOrNull(id) && !isEmptyOrNull(comment)) {
          removeEntry(
            { id, body: comment },
            {
              onSuccess: () =>
                showSuccessPrompt(
                  t('prompts.remove.success', {
                    returnObjects: true
                  }) as SLRPromptLabels,
                  { symbol: getIcon('fat', 'check-circle') },
                  true
                )
            }
          );
          handleHideReviewCommentPrompt();
        }
      },
      onCancel: handleHideReviewCommentPrompt
    });
  }, [
    form,
    handleHideReviewCommentPrompt,
    id,
    isRemoveEntryLoading,
    removeEntry,
    showSuccessPrompt,
    t
  ]);

  const { badgeName, badgeColor } = getBadgeInfo(status, true);

  const isPublished = useMemo(
    () => status === EntryDtoStatusEnum.Verffentlicht,
    [status]
  );

  const allowedActions: ReactNode = useMemo(() => {
    if (status === EntryDtoStatusEnum.InPrfung) {
      return (
        <>
          <SLRLoadingButton
            isLoading={isRejectEntryLoading}
            disabled={isApproveEntryLoading}
            size="lg"
            variant="outline-danger"
            width="7.5rem"
            onClick={handleRejectEntry}
          >
            {t('reject')}
          </SLRLoadingButton>
          <SLRLoadingButton
            isLoading={isApproveEntryLoading}
            disabled={isRejectEntryLoading}
            size="lg"
            variant="primary"
            width="8.75rem"
            onClick={handleApproveEntry}
          >
            {t('approve')}
          </SLRLoadingButton>
        </>
      );
    }
    if (
      status === EntryDtoStatusEnum.NderungenNotwendig ||
      status === EntryDtoStatusEnum.BereitZumVerffentlichen
    ) {
      return (
        <SLRLoadingButton
          isLoading={isWithdrawEntryLoading}
          size="lg"
          variant="outline-danger"
          width="16rem"
          onClick={handleWithdrawEntry}
        >
          {t('withdraw')}
        </SLRLoadingButton>
      );
    }

    if (status === EntryDtoStatusEnum.Verffentlicht) {
      return (
        <SLRLoadingButton
          isLoading={isRemoveEntryLoading}
          size="lg"
          variant="outline-danger"
          width="17rem"
          onClick={handleRemoveEntry}
        >
          {t('remove')}
        </SLRLoadingButton>
      );
    }
  }, [
    handleApproveEntry,
    handleRejectEntry,
    handleRemoveEntry,
    handleWithdrawEntry,
    isApproveEntryLoading,
    isRejectEntryLoading,
    isRemoveEntryLoading,
    isWithdrawEntryLoading,
    status,
    t
  ]);

  const editIcon = (
    <Link
      className="btn btn-outline-primary d-flex align-items-center h-100"
      to="edit"
      state={{ previousPath: `${MARKETPLACE_REVIEW_PATH}/${id}` }}
      title={t('navigateTo.edit')}
    >
      <FontAwesomeIcon icon={getIcon('fal', 'pencil')} fontSize="1.5rem" />
    </Link>
  );

  return (
    <>
      {successPromptProps && <SLRPrompt {...successPromptProps} />}
      {reviewCommentPromptProps && (
        <SLRPrompt
          enforceFocus={false}
          className="entry-review-actions-comment-prompt"
          size="xl"
          textEditor={{
            isInvalid:
              reviewCommentType !== ReviewCommentType.Approve &&
              isEmptyOrNull(form.watch('comment')),
            editor: (
              <SLRTextEditor
                id="comment"
                className="mt-3"
                control={form.control}
                name="comment"
                rows={8}
                placeholder={t(`input.placeholder.${reviewCommentType}`)}
                maxLength={REVIEW_COMMENT_MAX_CHAR_LENGTH}
                isInvalid={
                  reviewCommentType !== ReviewCommentType.Approve &&
                  !!form.formState.errors.comment
                }
                errorMessage={
                  reviewCommentType !== ReviewCommentType.Approve
                    ? form.formState.errors.comment?.message
                    : undefined
                }
              />
            )
          }}
          {...reviewCommentPromptProps}
        />
      )}
      <Container
        fluid
        className="entry-review-actions position-sticky bottom-0 bg-white"
      >
        <Row className="justify-content-between align-items-center text-center px-2 py-4 gap-3 gap-lg-0">
          <Col xs="12" sm="2" lg="1" className="d-flex justify-content-between">
            <Link
              className="p-0 btn btn-link"
              to={state?.previousPath ?? MARKETPLACE_REVIEW_PATH}
              replace
              title={t('navigateTo.overview')}
            >
              <FontAwesomeIcon
                icon={getIcon('fal', 'circle-chevron-left')}
                size="3x"
              />
            </Link>
            <div className="d-block d-sm-none">{editIcon}</div>
          </Col>

          <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <Row className="gap-3 d-flex justify-content-center justify-content-lg-end">
              {isPublished && (
                <Col xs="auto" className="p-0 d-flex justify-content-end">
                  <LiveBadge
                    label={t('publishedAt', {
                      date: new Date(publishedAt ?? '').toLocaleDateString(
                        'de-DE'
                      )
                    })}
                    position="static"
                  />
                </Col>
              )}

              <Col xs="auto" className="p-0 d-flex justify-content-end">
                <Badge bg={badgeColor} className="fs-5 text-center">
                  {badgeName}
                </Badge>
              </Col>

              <Col xs="auto" className="p-0 d-flex justify-content-start">
                <SLRLabelIcon
                  size="xl"
                  label={
                    <Row className="lh-1 p-0 flex-column">
                      <Col className="date">
                        {new Date(submittedAt ?? '').toLocaleDateString(
                          'de-DE'
                        )}
                      </Col>
                      <Col className="label">{t('submittedAt')}</Col>
                    </Row>
                  }
                  icon={getIcon('fal', 'calendar-arrow-down')}
                />
              </Col>
            </Row>
          </Col>

          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 8, order: 1 }}
            lg={{ span: 4, order: 2 }}
            className="d-flex justify-content-center justify-content-sm-end gap-3"
          >
            <div className="d-none d-sm-block">{editIcon}</div>
            {allowedActions}
          </Col>
        </Row>
      </Container>
    </>
  );
};
