/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { ResponseError } from '@SLR/marketplaceService-sdk';
import { SLRPageHead } from '@SLR/shared-library';
import { ErrorLogo, NoAccessLogo } from 'assets';
import { CONTACT_URL, LANDING_PAGE_URL, MARKETPLACE_PATH } from 'configs';
import { FC, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './ErrorView.scss';

export type ErrorViewProps = {
  errorMessage?: string;
  showHomeButton?: boolean;
  homeButtonPath?: string;
  error?: ResponseError | null;
};

export const ErrorView: FC<ErrorViewProps> = ({
  errorMessage,
  showHomeButton,
  homeButtonPath = MARKETPLACE_PATH,
  error
}) => {
  const { t } = useTranslation();

  const noAccess = useMemo(
    () => error?.response?.status === 403,
    [error?.response.status]
  );

  const generalError = (
    <Container className="error-view">
      <Row className="text-center my-5 d-flex flex-column justify-content-center align-items-center bg-white gap-4">
        <SLRPageHead
          labels={{
            title: t('error.title'),
            description: errorMessage ?? t('error.noResponse')
          }}
        />
        <Col xs="12">
          <ErrorLogo width="100%" />
        </Col>
        <Col xs="12">
          <p>
            <Trans
              t={t}
              i18nKey="error.options"
              components={[
                <Link target="_blank" to={CONTACT_URL} key="CONTACT_URL" />
              ]}
            />
          </p>
        </Col>
        {showHomeButton && (
          <Col>
            <Link to={homeButtonPath} replace>
              <Button size="lg" variant="primary">
                {t('error.toHome')}
              </Button>
            </Link>
          </Col>
        )}
      </Row>
    </Container>
  );

  const accessError = (
    <Container className="error-view">
      <Row className="text-center my-5 d-flex flex-column justify-content-center align-items-center bg-white gap-4">
        <Col xs="12">
          <NoAccessLogo width="100%" />
        </Col>
        <Col xs="12">
          <h2>{t('error.noAccess')}</h2>
        </Col>
        <Col>
          <Button size="lg" variant="primary" href={LANDING_PAGE_URL}>
            {t('error.toLandingPage')}
          </Button>
        </Col>
      </Row>
    </Container>
  );

  return noAccess ? accessError : generalError;
};
