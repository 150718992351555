/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ExperienceReportDto,
  ExperienceReportDtoRecommendationEnum
} from '@SLR/marketplaceService-sdk';
import {
  concat,
  getIcon,
  isEmptyOrNull,
  showSuccessToast,
  SLRFormInfo,
  SLRPrompt,
  SLRPromptProps,
  SLRToast,
  useGetCurrentUserProfile
} from '@SLR/shared-library';
import {
  useCreateExperienceReport,
  useDeleteExperienceReport,
  useUpdateExperienceReport
} from 'hooks';
import { useAccess, useOrganization } from 'providers';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExperienceReportInput, ExperienceReportSchema, LikeForm } from '.';

import './ReportForm.scss';

export type ReportFormProps = {
  report?: ExperienceReportDto;
  entryId?: string | null;
  entryName?: string | null;
  onLoading: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
  onCancel?: () => void;
};

export const ReportForm: FC<ReportFormProps> = ({
  report,
  entryId,
  entryName,
  onLoading,
  onSuccess,
  onCancel
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.ratings.experience.form'
  });

  const { isDemoAccess } = useAccess();
  const { selectedOrganizationId } = useOrganization();
  const { data: user } = useGetCurrentUserProfile();

  const { mutate: createReport, isPending: isCreateReportLoading } =
    useCreateExperienceReport();
  const { mutate: updateReport, isPending: isUpdateReportLoading } =
    useUpdateExperienceReport();
  const { mutate: deleteReport, isPending: isDeleteReportLoading } =
    useDeleteExperienceReport(entryId);

  useEffect(() => {
    onLoading(isCreateReportLoading || isUpdateReportLoading);
  }, [isCreateReportLoading, isUpdateReportLoading, onLoading]);

  const {
    formState: { errors },
    handleSubmit,
    register,
    control
  } = useForm<ExperienceReportInput>({
    values: {
      ...report,
      emailAddress: report?.emailAddress ?? user?.emailAddress,
      allowReporterName: !isEmptyOrNull(report?.reporterName)
    },
    defaultValues: {
      recommendation: ExperienceReportDtoRecommendationEnum.NotSpecified
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(ExperienceReportSchema)
  });

  const isEdit = report && !isEmptyOrNull(report.id);

  const [promptProps, setPromptProps] = useState<SLRPromptProps | null>(null);
  const handleHidePrompt = () => setPromptProps(null);

  const handleFormSubmit = useCallback(
    (input: ExperienceReportInput) => {
      // Ts-Expect-Error needed as form allows null values
      const experienceReportDto: ExperienceReportDto = {
        organizationId: selectedOrganizationId,
        // eslint-disable-next-line
        // @ts-expect-error
        emailAddress: input.emailAddress,
        // eslint-disable-next-line
        // @ts-expect-error
        entryId,
        // eslint-disable-next-line
        // @ts-expect-error
        recommendation: input.recommendation,
        // eslint-disable-next-line
        // @ts-expect-error
        reporterName: input.allowReporterName
          ? concat(user?.firstName, user?.lastName)
          : null
      };

      if (isEdit) {
        updateReport(
          { id: report.id, experienceReportDto },
          {
            onSuccess: () => {
              showSuccessToast('toasts.experienceReportUpdate.success');
              onSuccess();
            }
          }
        );
      } else {
        createReport(
          { experienceReportDto },
          {
            onSuccess: () => {
              showSuccessToast('toasts.experienceReportCreate.success');
              onSuccess();
            }
          }
        );
      }
    },
    [
      createReport,
      entryId,
      isEdit,
      onSuccess,
      report?.id,
      selectedOrganizationId,
      updateReport,
      user?.firstName,
      user?.lastName
    ]
  );

  const handleDelete = useCallback(
    () =>
      setPromptProps({
        labels: t('delete', {
          returnObjects: true
        }),
        confirmButtonVariant: 'danger',
        isLoading: isDeleteReportLoading,
        onConfirm: () => {
          if (report && !isEmptyOrNull(report?.id)) {
            deleteReport(
              { id: report.id },
              {
                onSuccess: () => {
                  showSuccessToast('toasts.experienceReportDelete.success');
                  onSuccess();
                },
                onSettled: handleHidePrompt
              }
            );
          }
        },
        onCancel: () => {
          handleHidePrompt();
        }
      }),
    [deleteReport, isDeleteReportLoading, onSuccess, report, t]
  );

  return (
    <>
      {promptProps && <SLRPrompt {...promptProps} />}
      <Form onSubmit={handleSubmit(handleFormSubmit)} className="report-form">
        <Row className="gap-4">
          <Col xs="12" md="6">
            <Form.Label htmlFor="emailAddress">{t('email')}</Form.Label>
            <Form.Control
              id="emailAddress"
              type="text"
              autoComplete="true"
              className="form-control-lg"
              isInvalid={!!errors?.emailAddress}
              {...register('emailAddress')}
            />
            <SLRFormInfo
              className={isEdit ? 'edit' : ''}
              text={errors?.emailAddress?.message}
              isInvalid={!!errors?.emailAddress}
            />
          </Col>
          <Col xs="12">
            <LikeForm
              control={control}
              name="recommendation"
              entryName={entryName}
              isEdit={isEdit}
            />
          </Col>
          <Col xs="12">
            <Row className="gap-3">
              <Col xs="12">
                <Form.Check
                  className={`m-0 ps-0 pt-3 fw-bold name-checkbox ${isEdit ? 'edit' : ''}`}
                  type="checkbox"
                  id="allowReporterName"
                  label={t('nameOption')}
                  {...register('allowReporterName')}
                />
              </Col>
              {isEdit && (
                <Col xs="12" md="8">
                  <SLRToast
                    toastId="rating-info"
                    type="warning"
                    closeable={false}
                  >
                    {t('edit.info')}
                  </SLRToast>
                </Col>
              )}
            </Row>
          </Col>
          <Col xs="12">
            {isEdit ? (
              <Row className="border-top mx-n4 p-3 justify-content-between">
                <Col className="px-0">
                  <Button
                    variant="outline-danger"
                    size="lg"
                    disabled={isDemoAccess}
                    title={t('edit.delete')}
                    onClick={handleDelete}
                  >
                    <FontAwesomeIcon icon={getIcon('fal', 'trash-can')} />
                  </Button>
                </Col>
                <Col className="px-0 d-flex justify-content-end gap-3">
                  <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={onCancel}
                  >
                    {t('edit.cancel')}
                  </Button>
                  <Button
                    size="lg"
                    variant="primary"
                    type="submit"
                    disabled={isDemoAccess}
                  >
                    {t('edit.update')}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button
                variant="white"
                size="lg"
                type="submit"
                className="text-primary"
                disabled={isDemoAccess}
              >
                <FontAwesomeIcon
                  icon={getIcon('fal', 'paper-plane')}
                  className="me-2"
                />
                {t('publish')}
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};
