/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDtoStatusEnum } from '@SLR/marketplaceService-sdk';
import {
  SLRLabelIcon,
  SLRModal,
  getIcon,
  isEmptyOrNull
} from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as MarkdownPreview } from '@uiw/react-markdown-preview';
import { useMarketplace } from 'providers';
import { FC, ReactElement, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  Row,
  Spinner,
  SplitButton
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getBadgeInfo } from 'utils';

import './Actions.scss';

export type ActionsProps = {
  status?: EntryDtoStatusEnum | null;
  isLoading?: boolean;
  reviewComment?: string | null;
  onPreview: () => void;
  onSave: () => void;
  onSubmit: () => void;
  onPublish: () => void;
  onUnPublish: () => void;
  onDelete: () => void;
  onCancel: () => void;
};

export const Actions: FC<ActionsProps> = ({
  status = EntryDtoStatusEnum.Entwurf,
  isLoading = false,
  reviewComment,
  onPreview,
  onSave,
  onSubmit,
  onPublish,
  onUnPublish,
  onDelete,
  onCancel
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.actions'
  });

  const { isReviewContext } = useMarketplace();
  const { badgeColor } = getBadgeInfo(status);

  const allowedActions: ReactElement[] = useMemo(() => {
    const actions = [];
    if (
      status === EntryDtoStatusEnum.Entwurf ||
      status === EntryDtoStatusEnum.NderungenNotwendig
    ) {
      actions.push(
        <SLRLabelIcon
          icon={getIcon('fal', 'clipboard-check')}
          label={t(`submit`)}
          onClick={onSubmit}
        />
      );
    }
    if (status === EntryDtoStatusEnum.BereitZumVerffentlichen) {
      actions.push(
        <SLRLabelIcon
          icon={getIcon('fal', 'rocket-launch')}
          label={t(`publish`)}
          onClick={onPublish}
        />
      );
    }
    if (status === EntryDtoStatusEnum.Verffentlicht) {
      actions.push(
        <SLRLabelIcon
          icon={getIcon('fal', 'ban')}
          label={t(`unpublish`)}
          onClick={onUnPublish}
        />
      );
    }

    actions.push(
      <SLRLabelIcon
        icon={getIcon('fal', 'trash-can')}
        label={t(`delete`)}
        className="text-danger"
        onClick={onDelete}
      />
    );

    return actions;
  }, [onDelete, onPublish, onSubmit, onUnPublish, status, t]);

  // #region ReviewComment
  const [showReviewComment, setReviewComment] = useState<boolean>(false);

  const handleShowReviewComment = () => setReviewComment(true);
  const handleHideReviewComment = () => setReviewComment(false);
  // #endregion

  return (
    <>
      {showReviewComment && (
        <SLRModal
          title={t('hints')}
          isOpen
          size="lg"
          onHide={handleHideReviewComment}
          onClose={handleHideReviewComment}
          fullscreen="md-down"
          className="entry-review-comment"
        >
          <Row className="gap-3">
            <Col xs="12">
              <h2>{t('reviewCommentModal.title')}</h2>
            </Col>
            <Col xs="12">
              <MarkdownPreview
                source={reviewComment ?? ''}
                className="table-responsive fs-5 markdown-style"
                wrapperElement={{ 'data-color-mode': 'light' }}
              />
            </Col>
          </Row>
        </SLRModal>
      )}
      <Container
        fluid
        className="entry-create-actions position-sticky bottom-0 bg-white"
      >
        <Container>
          <Row className="justify-content-between align-items-center text-center py-4">
            <Col xs="12" lg="3" className="pb-3 pb-lg-0">
              <Row className="align-items-center justify-content-center">
                <Col className="d-flex align-items-center text-center text-lg-start">
                  {!isReviewContext && (
                    <>
                      <Badge
                        bg={badgeColor}
                        className="fs-5 text-center rounded-pill"
                      >
                        {status}
                      </Badge>
                      {!isEmptyOrNull(reviewComment) &&
                        (status === EntryDtoStatusEnum.NderungenNotwendig ||
                          status ===
                            EntryDtoStatusEnum.BereitZumVerffentlichen) && (
                          <Button
                            variant="link"
                            className="p-0 ps-3 fs-5 text-decoration-underline cursor-pointer text-danger"
                            onClick={handleShowReviewComment}
                          >
                            <span>
                              {t(
                                status === EntryDtoStatusEnum.NderungenNotwendig
                                  ? 'why'
                                  : 'hints'
                              )}
                            </span>
                            <FontAwesomeIcon
                              icon={getIcon('fal', 'message-exclamation')}
                              size="1x"
                              className="ps-1 text-danger position-absolute top-0 pt-1 mt-3"
                            />
                          </Button>
                        )}
                    </>
                  )}
                </Col>
                <Col xs="auto" className="d-lg-none fs-6 text-muted">
                  {t('mandatoryFields')}
                </Col>
              </Row>
            </Col>

            <Col lg="auto"></Col>

            <Col xs="12" lg="auto">
              <Row className="justify-content-between align-items-center">
                <Col lg="auto" className="d-none d-lg-block fs-6 text-muted">
                  {t('mandatoryFields')}
                </Col>
                {isReviewContext ? (
                  <>
                    <Col xs="auto">
                      <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={onCancel}
                      >
                        {t('cancel')}
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button size="lg" variant="primary" onClick={onSave}>
                        {t('save')}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs="auto">
                      <Button
                        size="lg"
                        variant="outline-primary"
                        onClick={onPreview}
                      >
                        {t('preview')}
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <SplitButton
                        variant="primary"
                        className="action-button"
                        title={
                          isLoading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                            />
                          ) : (
                            t('save')
                          )
                        }
                        disabled={isLoading}
                        drop="down-centered"
                        size="lg"
                        onClick={onSave}
                      >
                        {allowedActions.map((action, index) => (
                          <div key={`entryCreate-actions-${index}`}>
                            {index === allowedActions.length - 1 &&
                              allowedActions.length !== 1 && (
                                <Dropdown.Divider />
                              )}
                            <Dropdown.Item>{action}</Dropdown.Item>
                          </div>
                        ))}
                      </SplitButton>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
