/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

export * from './like/LikeForm';
export * from './modal/ReportModal';
export * from './ReportForm';

import { ExperienceReportDtoRecommendationEnum } from '@SLR/marketplaceService-sdk';
import { EMAIL_PATTERN_VALIDATION, isEmptyOrNull } from '@SLR/shared-library';
import { ObjectSchema, boolean, mixed, object, string } from 'yup';

export type ExperienceReportInput = {
  emailAddress?: string | null;
  allowReporterName?: boolean | null;
  recommendation?: ExperienceReportDtoRecommendationEnum | null;
};

export const ExperienceReportSchema: ObjectSchema<ExperienceReportInput> =
  object().shape({
    emailAddress: string()
      .required(
        'marketplace.entry.detail.ratings.experience.form.error.email.required'
      )
      .matches(
        EMAIL_PATTERN_VALIDATION,
        'marketplace.entry.detail.ratings.experience.form.error.email.invalid'
      )
      .test(
        'isEmpty',
        'marketplace.entry.detail.ratings.experience.form.error.email.required',
        (value) => !isEmptyOrNull(value)
      )
      .default(null),
    allowReporterName: boolean().notRequired().default(false),
    recommendation: mixed<ExperienceReportDtoRecommendationEnum>()
      .required()
      .default(ExperienceReportDtoRecommendationEnum.NotSpecified)
  });
