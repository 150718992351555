/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  EntryDtoCategoriesEnum,
  EntryDtoStatusEnum
} from '@SLR/marketplaceService-sdk';
import { isEmptyOrNull, useAuth } from '@SLR/shared-library';
import {
  EntryDtoReviewStatusEnum,
  MARKETPLACE_MANAGEMENT_PATH,
  MARKETPLACE_PATH,
  MARKETPLACE_REVIEW_PATH,
  REVIEW_ROLE,
  SortMode,
  getSortMode
} from 'configs';
import { t } from 'i18next';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export type MarketplaceContextValues = {
  hasAccessByRole: boolean;
  isManagementContext: boolean;
  isReviewContext: boolean;
  filters: string[];
  selectedFilter: string;
  setFilter: (filter: string) => void;
  sortModes: SortMode[];
  selectedSortMode: SortMode;
  setSortMode: (sortMode: SortMode) => void;
  getMarketplacePath: (filter?: string, sortMode?: SortMode) => string;
  updatePageTitle: (title?: string | null) => void;
};

const defaultMarketplaceContextValues: MarketplaceContextValues = {
  hasAccessByRole: false,
  isManagementContext: false,
  isReviewContext: false,
  filters: [],
  selectedFilter: t('marketplace.filter.all'),
  setFilter: () => {},
  sortModes: [],
  selectedSortMode: SortMode.Newest,
  setSortMode: () => {},
  getMarketplacePath: () => MARKETPLACE_PATH,
  updatePageTitle: () => {}
};

const MarketplaceContext = createContext<MarketplaceContextValues>(
  defaultMarketplaceContextValues
);

export const useMarketplace = () => useContext(MarketplaceContext);

export const MarketplaceProvider: FC<PropsWithChildren> = ({ children }) => {
  // #region User role
  const { hasClientRole } = useAuth();
  const hasAccessByRole = useMemo(
    () => hasClientRole(REVIEW_ROLE),
    [hasClientRole]
  );
  // #endregion

  // #region Enable context based path
  const { pathname } = useLocation();

  const isManagementContext = useMemo(
    () => pathname.includes(MARKETPLACE_MANAGEMENT_PATH),
    [pathname]
  );

  const isReviewContext = useMemo(
    () => pathname.includes(MARKETPLACE_REVIEW_PATH),
    [pathname]
  );
  // #endregion

  // #region Handling filter and sort mode selection
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedFilter = useMemo(
    () => searchParams.get('filter') ?? t('marketplace.filter.all'),
    [searchParams]
  );

  const selectedSortMode = useMemo(
    () => getSortMode(searchParams.get('sortMode')),
    [searchParams]
  );

  const filters = useMemo(() => {
    const all = t('marketplace.filter.all');
    if (isReviewContext) {
      return [all, ...Object.values(EntryDtoReviewStatusEnum)];
    } else {
      const categories = Object.values(
        isManagementContext ? EntryDtoStatusEnum : EntryDtoCategoriesEnum
      ).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      return [all, ...categories];
    }
  }, [isManagementContext, isReviewContext]);

  const setFilter = useCallback(
    (filter: string) =>
      setSearchParams({
        filter,
        sortMode: selectedSortMode
      }),
    [selectedSortMode, setSearchParams]
  );

  const sortModes = useMemo(
    () =>
      Object.values(SortMode).filter(
        (sortMode) => isManagementContext || sortMode !== SortMode.LastEdited
      ),
    [isManagementContext]
  );

  const setSortMode = useCallback(
    (sortMode: SortMode) =>
      setSearchParams({
        filter: selectedFilter.toString(),
        sortMode
      }),
    [selectedFilter, setSearchParams]
  );
  // #endregion

  // #region Handling marketplace path generation
  const getMarketplacePath = useCallback(
    (filter = selectedFilter, sortMode = selectedSortMode): string =>
      `${
        isReviewContext
          ? MARKETPLACE_REVIEW_PATH
          : isManagementContext
            ? MARKETPLACE_MANAGEMENT_PATH
            : MARKETPLACE_PATH
      }?filter=${filter}&sortMode=${sortMode}`,
    [isManagementContext, isReviewContext, selectedFilter, selectedSortMode]
  );
  // #endregion

  const updatePageTitle = useCallback((title?: string | null) => {
    document.title = isEmptyOrNull(title)
      ? t('appName')
      : `${title} - ${t('appName')}`;
  }, []);

  const marketplaceContextValues: MarketplaceContextValues = useMemo(
    () => ({
      hasAccessByRole,
      isManagementContext,
      isReviewContext,
      filters,
      selectedFilter,
      setFilter,
      sortModes,
      selectedSortMode,
      setSortMode,
      getMarketplacePath,
      updatePageTitle
    }),
    [
      filters,
      getMarketplacePath,
      hasAccessByRole,
      isManagementContext,
      isReviewContext,
      selectedFilter,
      selectedSortMode,
      setFilter,
      setSortMode,
      updatePageTitle,
      sortModes
    ]
  );

  return (
    <MarketplaceContext.Provider value={marketplaceContextValues}>
      {children}
    </MarketplaceContext.Provider>
  );
};
