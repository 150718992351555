/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto, EntryDtoStatusEnum } from '@SLR/marketplaceService-sdk';
import {
  SLRLabelIcon,
  emptyAsFallback,
  getIcon,
  hasValue,
  isEmptyOrNull,
  useGetOrganization
} from '@SLR/shared-library';
import { LiveBadge } from 'components';
import { useMarketplace } from 'providers';
import { FC, useMemo } from 'react';
import { Badge, Card, Col, ColProps, Placeholder, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getBadgeInfo } from 'utils';
import { EntryImage } from './EntryImage';

import './EntryItem.scss';

// Note: In case the view is updated, please also update Entry SLRSpinner to reflect the changes
export type EntryProps = {
  entry?: Nullable<EntryDto>;
  isPreview?: boolean;
  className?: ColProps['className'];
};

export const EntryItem: FC<EntryProps> = ({
  entry,
  isPreview,
  className = ''
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.item'
  });

  const { isManagementContext, isReviewContext, getMarketplacePath } =
    useMarketplace();

  const { data: organization, isLoading: isOrganizationLoading } =
    useGetOrganization(entry?.organizationId);

  const entryUrl = useMemo(() => {
    const id = entry?.id ?? '';
    return isManagementContext ? id + '/edit' : id;
  }, [entry?.id, isManagementContext]);

  const { badgeName, badgeColor } = getBadgeInfo(
    entry?.status,
    isReviewContext
  );

  const isDefaultView = useMemo(
    () => isPreview || !(isManagementContext || isReviewContext),
    [isManagementContext, isPreview, isReviewContext]
  );

  const showLiveBadge = useMemo(
    () =>
      !isPreview &&
      isReviewContext &&
      entry?.status === EntryDtoStatusEnum.Verffentlicht,
    [entry?.status, isPreview, isReviewContext]
  );

  return (
    <>
      {entry && (
        <Col className={`py-3 entry ${className}`}>
          <Link
            to={isPreview ? '' : entryUrl}
            state={{ previousPath: getMarketplacePath() }}
            className={`${isPreview ? 'pe-none' : ''} entry-link`}
          >
            <Card className="entry-card">
              {showLiveBadge && <LiveBadge label={t('live')} />}
              <EntryImage
                entryName={entry?.name}
                imageId={entry?.previewImageId}
              />
              <Card.Body className="entry-values px-4 pb-3">
                <Row className="justify-content-end">
                  <Col xs="auto">
                    <Badge
                      className="entry-badge"
                      bg={isDefaultView ? 'primary' : badgeColor}
                    >
                      {isDefaultView
                        ? hasValue(entry?.categories)
                          ? entry.categories.slice(0, 2).join(' | ')
                          : t('placeholder.filter')
                        : badgeName}
                    </Badge>
                  </Col>
                </Row>
                <Row className="entry-name">
                  <Col>
                    {emptyAsFallback(t('placeholder.name'), entry.name)}
                  </Col>
                </Row>

                <Row className="entry-organization-name">
                  <Col>
                    {isOrganizationLoading ? (
                      <Placeholder xs={4} />
                    ) : (
                      organization?.name
                    )}
                  </Col>
                </Row>

                <Row className="entry-description pt-3">
                  <Col>
                    {isDefaultView ? (
                      entry.shortDescription
                    ) : (
                      <SLRLabelIcon
                        icon={getIcon(
                          'fal',
                          isReviewContext
                            ? 'calendar-arrow-down'
                            : 'calendar-lines-pen'
                        )}
                        label={t(
                          isReviewContext ? 'submittedAt' : 'updatedAt',
                          {
                            date: new Date(
                              entry.updatedAt ?? ''
                            ).toLocaleDateString('de-DE')
                          }
                        )}
                      />
                    )}
                  </Col>
                </Row>
              </Card.Body>
              {isDefaultView && (
                <Card.Footer className="entry-price px-4 pt-0 pb-3">
                  <SLRLabelIcon
                    label={
                      isEmptyOrNull(entry?.priceInformation)
                        ? t('placeholder.price')
                        : entry?.priceInformation
                    }
                    icon={getIcon('fal', 'circle-euro')}
                  />
                </Card.Footer>
              )}
            </Card>
          </Link>
        </Col>
      )}
    </>
  );
};
