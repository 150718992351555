/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { getIcon } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MARKETPLACE_MANAGEMENT_CREATE_ENTRY_PATH } from 'configs';
import { useMarketplace } from 'providers';
import { FC } from 'react';
import { Card, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './EntryItem.scss';

export const EntryCreate: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.item'
  });

  const { getMarketplacePath } = useMarketplace();

  return (
    <Col className="py-3 entry">
      <Link
        to={MARKETPLACE_MANAGEMENT_CREATE_ENTRY_PATH}
        state={{ previousPath: getMarketplacePath() }}
        className="entry-link"
      >
        <Card className="entry-card">
          <Card.Body className="entry-create">
            <FontAwesomeIcon icon={getIcon('fal', 'circle-plus')} size="4x" />
            {t('new')}
          </Card.Body>
        </Card>
      </Link>
    </Col>
  );
};
