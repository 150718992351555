/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { isEmptyOrNull } from '@SLR/shared-library';
import { t } from 'i18next';

// Referenced Urls
export const LANDING_PAGE_URL = import.meta.env.VITE_LANDING_PAGE_URL as string;
export const LANDING_PAGE_MUNICIPALITIES_URL = import.meta.env
  .VITE_LANDING_PAGE_MUNICIPALITIES_URL as string;
export const LANDING_PAGE_SOLUTION_PROVIDER_URL = import.meta.env
  .VITE_LANDING_PAGE_SOLUTION_PROVIDER_URL as string;
export const CONTACT_URL = import.meta.env.VITE_CONTACT_URL as string;
export const SUPPORT_URL = import.meta.env.VITE_SUPPORT_URL as string;
export const SLR_WEBSITE_URL = import.meta.env.VITE_SLR_WEBSITE_URL as string;
export const FAQ_URL = import.meta.env.VITE_FAQ_URL as string;

// User Portal Url
const USER_PORTAL = import.meta.env.VITE_USER_PORTAL_URL as string;
export const createUserPortalUrl = (organizationId?: string) => {
  let url = USER_PORTAL;
  url += isEmptyOrNull(organizationId)
    ? 'profile'
    : `organizations/${organizationId}`;
  url += `?solution_uri=${window.location.href}&solution_name=${t('appName')}&solution_color=3688d9`;
  return url;
};
