/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

export const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT as string;

export const isLocal = APP_ENVIRONMENT === 'local';
export const isDev = APP_ENVIRONMENT === 'dev';
export const isStage = APP_ENVIRONMENT === 'stage';
export const isProd = APP_ENVIRONMENT === 'prod';
export const isDemo = APP_ENVIRONMENT === 'demo';
