/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { getIcon } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { Dropdown, RowProps } from 'react-bootstrap';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './PriceDropdownForm.scss';

export type PriceDropdownFormProps<T extends FieldValues> = {
  id: string;
  control: Control<T>;
  name: Path<T>;
  options: string[];
  isInvalid?: boolean;
  className?: RowProps['className'];
};

export const PriceDropdownForm = <T extends FieldValues>({
  id,
  control,
  name,
  options,
  isInvalid,
  className = ''
}: PriceDropdownFormProps<T>) => {
  const {
    field: { value, onChange }
  } = useController({
    name,
    control
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.priceAndContact.priceInformation'
  });

  const selectedOption = useMemo(
    () =>
      value === null || value === undefined
        ? t('pleaseSelect')
        : value === t('options.onRequest') || value === t('options.free')
          ? value
          : t('options.ownDescription'),
    [t, value]
  );

  return (
    <Dropdown className={`price-dropdown-form ${className}`}>
      <Dropdown.Toggle
        id={id}
        variant="outline-neutral"
        size="lg"
        className={`price-toggle ${isInvalid ? 'border-danger' : ''}`}
      >
        {selectedOption}
        <FontAwesomeIcon icon={getIcon('fas', 'sort')} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            key={option}
            active={option === value}
            className="price-item"
            onClick={() => {
              onChange(
                option === t('options.onRequest') ||
                  option === t('options.free')
                  ? option
                  : ''
              );
            }}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
