/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto } from '@SLR/marketplaceService-sdk';
import { default as MarkdownPreview } from '@uiw/react-markdown-preview';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './Price.scss';

export type PriceProps = {
  entry?: Nullable<EntryDto>;
};

export const Price: FC<PriceProps> = ({ entry }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.price'
  });

  return (
    <div className="price">
      <h3 className="mb-3 title">
        {t('priceFor', {
          solution: entry?.name
        })}
      </h3>
      <MarkdownPreview
        className="table-responsive fs-5 markdown-style"
        source={entry?.longPriceInformation || ''}
        wrapperElement={{ 'data-color-mode': 'light' }}
      />
    </div>
  );
};
