/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { useMarketplace } from 'providers';
import { FC } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './FilterSort.scss';

export const FilterSort: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace'
  });

  const {
    selectedFilter,
    filters,
    setFilter,
    selectedSortMode,
    sortModes,
    setSortMode
  } = useMarketplace();

  return (
    <Container className="filter-sort">
      <Row className="filter-sort-row gap-3">
        <Col xs="auto" className="p-0">
          <Dropdown id="category" className="category-dropdown">
            <Dropdown.Toggle
              variant="link"
              size="lg"
              className="category-toggle fs-1"
            >
              {selectedFilter}
            </Dropdown.Toggle>

            <Dropdown.Menu className="category-menu">
              {filters.map((filter) => (
                <Dropdown.Item
                  active={filter === selectedFilter}
                  key={filter}
                  className="category-item"
                  onClick={() => setFilter(filter)}
                >
                  {filter}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        {/*
          <Col xs="auto" className="p-0">
            <FontAwesomeIcon icon={faSearch} size="2x"/>
          </Col>
          */}
        <Col xs="auto" className="p-0">
          <Dropdown id="sort" className="sort-dropdown">
            <Dropdown.Toggle
              variant="outline-primary"
              size="lg"
              className="sort-toggle"
            >
              {t(`sort.${selectedSortMode}`)}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {sortModes.map((sortMode) => (
                <Dropdown.Item
                  key={sortMode}
                  className="sort-item"
                  active={sortMode === selectedSortMode}
                  onClick={() => setSortMode(sortMode)}
                >
                  {t(`sort.${sortMode}`)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </Container>
  );
};
