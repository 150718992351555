/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto } from '@SLR/marketplaceService-sdk';
import {
  SLRFormInfo,
  SLRMediaItem,
  getIcon,
  isEmptyOrNull
} from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultPersonLogo } from 'assets';
import { ContactTooltip } from 'components';
import { useSendContactMessage } from 'hooks';
import { useAccess, useMarketplace, useOrganization } from 'providers';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { EntryModalContentType } from '..';

import './ContactModal.scss';

const TEXT_MAX_LENGTH = 1000;

export type ContactModalProps = {
  entry?: Nullable<EntryDto>;
  organizationName?: string;
  onLoading: Dispatch<SetStateAction<boolean>>;
  onChange: Dispatch<SetStateAction<EntryModalContentType>>;
};

export const ContactModal: FC<ContactModalProps> = ({
  entry,
  organizationName,
  onLoading,
  onChange
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.contact.modal'
  });

  const { isDemoAccess } = useAccess();
  const { isReviewContext, isManagementContext } = useMarketplace();
  const { selectedOrganization } = useOrganization();

  const { mutate: sendContactMessage, isPending } = useSendContactMessage();

  const [message, setMessage] = useState<string>('');
  const messageLength = useMemo(() => message.length, [message]);

  const topics = useMemo(
    () => [
      {
        value: t('topics.buy', {
          solution: entry?.name
        }),
        label: (
          <Trans
            t={t}
            i18nKey="topics.buy"
            values={{ solution: entry?.name }}
            components={[<span className="topic-text" key="topics.buy" />]}
          />
        )
      },
      {
        value: t('topics.question', {
          solution: entry?.name
        }),
        label: (
          <Trans
            t={t}
            i18nKey="topics.question"
            values={{ solution: entry?.name }}
            components={[<span className="topic-text" key="topics.question" />]}
          />
        )
      },
      { value: t('topics.other'), label: <>{t('topics.other')}</> }
    ],
    [entry?.name, t]
  );

  const [topic, setTopic] = useState<string>(
    t('topics.buy', { solution: entry?.name })
  );

  const handleSendContactMessage = useCallback(() => {
    if (entry?.id && topic && !isEmptyOrNull(message)) {
      onLoading(true);
      sendContactMessage(
        {
          id: entry.id,
          organizationId: selectedOrganization?.organizationId ?? '',
          contactMessageRequest: {
            // <0> and </0> must be deleted to adapt topic name for email template
            topic: topic.replace('<0>', '').replace('</0>', ''),
            customerOrganizationName:
              selectedOrganization?.organizationName ?? '',
            message
          }
        },
        {
          onSettled: () => onLoading(false),
          onSuccess: () => onChange(EntryModalContentType.None)
        }
      );
    }
  }, [
    entry?.id,
    topic,
    message,
    selectedOrganization?.organizationId,
    selectedOrganization?.organizationName,
    onLoading,
    sendContactMessage,
    onChange
  ]);

  return (
    <div className="contact-modal">
      <Row className="align-items-center gap-4 gap-xl-0">
        <Col xs="12" xl="4" className="person">
          <Row className="justify-content-center">
            <Col xs="auto">
              <SLRMediaItem
                mediaItemId={entry?.contact?.pictureId}
                errorFallback={defaultPersonLogo}
                roundedCircle
                altFallback={t('pictureAlt', { solution: entry?.name })}
                className="picture mx-0"
              />
            </Col>

            <Col
              xs="12"
              className="d-flex flex-column align-items-center gap-2 mt-4 mb-5"
            >
              <Row>
                <Col className="fw-bold text-center name">
                  {entry?.contact?.name}
                </Col>
              </Row>
              {organizationName && (
                <Row>
                  <Col
                    onClick={() =>
                      onChange(EntryModalContentType.OrganizationDetail)
                    }
                    className="text-center cursor-pointer organization"
                  >
                    {organizationName}
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs="auto">
              <Row className="gap-4 align-items-center">
                {entry?.contact?.email && (
                  <ContactTooltip
                    content={entry?.contact.email}
                    link={`mailto:${entry?.contact.email}`}
                    icon={getIcon('fal', 'envelope-circle')}
                    isDisabled={isDemoAccess}
                  />
                )}
                {entry?.contact?.phone && (
                  <ContactTooltip
                    content={entry?.contact.phone}
                    link={`tel:${entry?.contact.phone}`}
                    icon={getIcon('fal', 'phone-circle')}
                    isDisabled={isDemoAccess}
                  />
                )}
                {entry?.contact?.website && (
                  <ContactTooltip
                    content={entry?.contact.website}
                    link={`${entry?.contact.website}`}
                    icon={getIcon('fal', 'globe')}
                    isDisabled={isDemoAccess}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs="12" xl="8" className="d-flex flex-column gap-4 message">
          <Row>
            <Col>
              <h2> {t('planTogether')}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor="topic">{t('topics.title')}</Form.Label>
            </Col>
            <Col xs="12">
              <Dropdown id="topic">
                <Dropdown.Toggle
                  variant="outline-neutral"
                  size="lg"
                  className="topic-toggle"
                >
                  <div className="d-flex topic-text">
                    {topics.find((t) => t.value === topic)?.label}
                  </div>

                  <FontAwesomeIcon
                    className="ms-4"
                    icon={getIcon('fas', 'sort')}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {topics.map(({ value, label }) => (
                    <Dropdown.Item
                      key={value}
                      active={value === topic}
                      className="topic-item"
                      onClick={() => setTopic(value)}
                    >
                      <span className="d-flex">{label}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row className="gap-2">
            <Col xs="12">
              <Form.Label htmlFor="message">
                {organizationName
                  ? t('messageTo', {
                      organization: organizationName
                    })
                  : t('message')}
              </Form.Label>
              <Form.Control
                id="message"
                as="textarea"
                rows={12}
                onChange={(ev) => setMessage(ev.target.value)}
                value={message}
              />
              <SLRFormInfo
                isInvalid={messageLength > TEXT_MAX_LENGTH}
                counter={{
                  count: messageLength,
                  maxCount: TEXT_MAX_LENGTH
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col xs="auto">
              <Button
                disabled={
                  isEmptyOrNull(message) ||
                  messageLength > TEXT_MAX_LENGTH ||
                  isPending ||
                  isDemoAccess ||
                  isReviewContext ||
                  isManagementContext
                }
                variant="primary"
                onClick={() => handleSendContactMessage()}
              >
                <FontAwesomeIcon
                  icon={getIcon('fal', 'paper-plane')}
                  className="me-2"
                />
                {t('sendMessage')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
