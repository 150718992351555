/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { useAuth } from '@SLR/shared-library';
import { City, Startup } from 'assets';
import {
  LANDING_PAGE_MUNICIPALITIES_URL,
  LANDING_PAGE_SOLUTION_PROVIDER_URL
} from 'configs';
import { FC } from 'react';
import { Button, Col, Row, RowProps } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import './Paywall.scss';

export type PaywallProps = {
  className?: RowProps['className'];
  style?: RowProps['style'];
};

export const Paywall: FC<PaywallProps> = ({ className = '', style }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'paywall'
  });

  const { isAuthenticated, login } = useAuth();

  return (
    <div id="paywall" className={`paywall ${className}`} style={style}>
      <Row className="text-center pb-5">
        <Col>
          <h1 className="fs-1">{t('title.discoverMore')}</h1>
          <h1 className="fs-1">
            <Trans t={t} i18nKey="title.becomePart" />
          </h1>
        </Col>
      </Row>

      <Row className="mb-5 gap-3 align-items-center">
        <Col>
          <City width="100%" />
        </Col>
        <Col xs="12" lg="6">
          <Row className="gap-3 text-center text-lg-start">
            <Col xs="12">
              <h3 className="fs-3">{t('municipality.title')}</h3>
              <p>{t('municipality.description')}</p>
            </Col>
            <Col>
              <Button
                id="reference-link"
                size="lg"
                target="_blank"
                href={LANDING_PAGE_MUNICIPALITIES_URL}
              >
                {t('learnMore')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="gap-3 align-items-center">
        <Col>
          <Startup width="100%" />
        </Col>
        <Col xs="12" lg="6">
          <Row className="gap-3 text-center text-lg-start">
            <Col xs="12">
              <h3 className="fs-3">{t('solutionProvider.title')}</h3>
              <p>{t('solutionProvider.description')}</p>
            </Col>
            <Col>
              <Button
                size="lg"
                target="_blank"
                href={LANDING_PAGE_SOLUTION_PROVIDER_URL}
              >
                {t('learnMore')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {!isAuthenticated && (
        <Row className="pt-5 text-center">
          <Col>
            <p className="lh-sm">{t('alreadyMember.description')}</p>
            <Trans
              t={t}
              i18nKey="alreadyMember.login"
              components={[
                <Button
                  id="reference-link"
                  variant="link"
                  key="login"
                  className="p-0 lh-sm fs-5"
                  onClick={() => login(window.location.href)}
                />
              ]}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
