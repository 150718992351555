/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  ExperienceReportDto,
  ExperienceReportDtoRecommendationEnum
} from '@SLR/marketplaceService-sdk';
import {
  getIcon,
  isEmptyOrNull,
  SLRLabelIcon,
  SLRMediaItem,
  useGetOrganization,
  useWindowSize
} from '@SLR/shared-library';
import { defaultOrganizationLogo } from 'assets';
import { ContactTooltip } from 'components';
import { useAccess, useOrganization } from 'providers';
import { FC } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './Report.scss';

export type ReportProps = {
  isEmptyState?: boolean;
  report?: ExperienceReportDto;
  onClick?: () => void;
};

export const Report: FC<ReportProps> = ({ isEmptyState, report, onClick }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.ratings.experience.report'
  });

  const { width } = useWindowSize();
  const { isDemoAccess } = useAccess();
  const { selectedOrganizationId } = useOrganization();
  const { data: organization } = useGetOrganization(report?.organizationId);

  return (
    <div
      className={`bg-body shadow report ${isEmptyState ? 'empty-state' : ''}`}
    >
      <Row className="align-items-center text-left">
        <Col xs="auto">
          <SLRMediaItem
            mediaItemId={organization?.logoId}
            altFallback={t('pictureAlt', { organization: organization?.name })}
            errorFallback={
              isEmptyState ? ['fal', 'shield-dog'] : defaultOrganizationLogo
            }
            roundedCircle
            className="organization-logo"
            iconSize="3x"
          />
        </Col>
        <Col>
          <Row>
            <Col xs="12" className="line-1 organization-name">
              {isEmptyState ? t('emptyState.organization') : organization?.name}
            </Col>
            {(isEmptyState || !isEmptyOrNull(report?.reporterName)) && (
              <Col xs="12" className="line-1 author">
                {t('author', {
                  name: isEmptyState
                    ? t('emptyState.author')
                    : report?.reporterName
                })}
              </Col>
            )}
            {report?.recommendation !==
              ExperienceReportDtoRecommendationEnum.NotSpecified && (
              <Col xs="12">
                <Badge
                  className={`mt-2 w-auto ${report?.recommendation === ExperienceReportDtoRecommendationEnum.ThumbsUp ? 'suggestion' : ''}`}
                  bg="danger"
                >
                  <SLRLabelIcon
                    label={width < 460 ? '' : t('recommendation')}
                    icon={getIcon(
                      'fas',
                      isEmptyState ||
                        report?.recommendation ===
                          ExperienceReportDtoRecommendationEnum.ThumbsUp
                        ? 'thumbs-up'
                        : 'thumbs-down'
                    )}
                    className="lh-base text-white flex-row-reverse"
                  />
                </Badge>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs="auto">
          <Row className="gap-2 flex-column">
            {!isDemoAccess &&
              selectedOrganizationId === report?.organizationId && (
                <Col xs="auto">
                  <SLRLabelIcon
                    icon={getIcon('fal', 'pen-circle')}
                    size="2x"
                    onClick={onClick}
                  />
                </Col>
              )}
            <Col xs="auto">
              <ContactTooltip
                content={report?.emailAddress}
                isDisabled={isEmptyState}
                link={`mailto:${report?.emailAddress}`}
                icon={getIcon('fal', 'envelope-circle')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
