/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { IconName } from '@fortawesome/fontawesome-svg-core';

export const Declaration = {
  Acceptability: 'acceptability',
  Privacy: 'privacy',
  Security: 'security',
  OpenSource: 'openSource'
} as const;

export type Declaration = (typeof Declaration)[keyof typeof Declaration];

export const DeclarationReference = {
  [Declaration.Acceptability]:
    'https://www.barrierefreiheit-dienstekonsolidierung.bund.de/Webs/PB/DE/gesetze-und-richtlinien/bitv2-0/bitv2-0-node.html',
  [Declaration.Privacy]:
    'https://eur-lex.europa.eu/eli/reg/2016/679/oj?locale=de',
  [Declaration.Security]:
    'https://www.bsi.bund.de/DE/Themen/Unternehmen-und-Organisationen/Standards-und-Zertifizierung/IT-Grundschutz/it-grundschutz_node.html',
  [Declaration.OpenSource]: 'https://opencode.de/de'
};

export const DeclarationIcon = {
  [Declaration.Acceptability]: 'wheelchair' as IconName,
  [Declaration.Privacy]: 'lock' as IconName,
  [Declaration.Security]: 'shield-check' as IconName,
  [Declaration.OpenSource]: 'code' as IconName
};
