/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EvaluatedQualityCriterionDto } from '@SLR/marketplaceService-sdk';
import {
  getIcon,
  getValue,
  SLRLabelIcon,
  SLRMediaItem
} from '@SLR/shared-library';
import { orderBy } from 'lodash';
import { FC, useContext } from 'react';
import { Accordion, AccordionContext, Badge, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './QualityCriterion.scss';

const NOT_POSSIBLE_KEY = 'Keine Bewertung möglich';
const DEFINITION_KEY = 'Definition';
const FULFILLED_KEY = 'Erfüllte Kriterien';
const NOT_FULFILLED_KEY = 'Nicht erfüllt oder erkannt';

export type QualityCriterionProps = {
  isRated: boolean;
  criterion: EvaluatedQualityCriterionDto;
};

export const QualityCriterion: FC<QualityCriterionProps> = ({
  isRated,
  criterion
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.ratings.ai.criterion'
  });

  const { activeEventKey } = useContext(AccordionContext);
  const eventKey = (criterion?.criteriaOrder ?? 0).toString();

  return (
    <Accordion.Item
      eventKey={eventKey}
      className="border-0 rounded-0 criterion"
    >
      <Accordion.Header className="border-bottom">
        <Row className="w-100 m-0 align-items-center justify-content-between">
          <Col xs="auto" className="p-0 ps-2 d-flex align-items-center">
            <SLRMediaItem
              mediaItemId={criterion.iconId}
              errorFallback={getIcon('fal', 'note-sticky')}
              iconSize="2x"
              hasShadow={false}
              className={`d-flex criterion-icon ${isRated ? 'rated' : 'not-rated'}`}
            />
          </Col>

          <Col
            className={`d-flex align-items-center justify-content-start ${isRated ? 'text-primary' : 'text-info-neutral'}`}
          >
            <span className="line-1 title">{criterion.name}</span>
          </Col>
          <Col
            xs="auto"
            className="pe-1 d-flex align-items-center justify-content-end"
          >
            {criterion.award && (
              <SLRLabelIcon
                icon={getIcon('fas', 'award')}
                label={t('top')}
                className="text-warning-dark fw-bold"
              />
            )}
            {!criterion.rated && (
              <Badge pill bg="info-neutral" className="tag-badge">
                {t('notRated')}
              </Badge>
            )}
          </Col>
          <Col
            xs="auto"
            className={`d-flex align-items-center arrow ${isRated ? 'text-primary' : 'text-info-neutral'}`}
          >
            <FontAwesomeIcon
              icon={getIcon('fal', 'chevron-down')}
              className={
                activeEventKey?.includes(eventKey) ? 'active' : 'inactive'
              }
            />
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body className="d-flex flex-column content">
        {!isRated && (
          <div>
            <h5 className="fw-bold">{NOT_POSSIBLE_KEY}</h5>
            <p>{getValue(NOT_POSSIBLE_KEY, criterion?.descriptions)}</p>
          </div>
        )}

        {criterion?.categorizedAttributes &&
          Object.keys(criterion.categorizedAttributes).map(
            (categorizedAttributeKey) => (
              <div key={categorizedAttributeKey}>
                <h5 className="fw-bold">{categorizedAttributeKey}</h5>
                {orderBy(
                  getValue(
                    categorizedAttributeKey,
                    criterion.categorizedAttributes
                  ),
                  ['attributeOrder'],
                  'asc'
                ).map(({ name }) => (
                  <Row key={name}>
                    <Col xs="auto" className="pe-0">
                      <FontAwesomeIcon
                        icon={getIcon(
                          'fas',
                          categorizedAttributeKey === FULFILLED_KEY
                            ? 'circle-check'
                            : categorizedAttributeKey === NOT_FULFILLED_KEY
                              ? 'circle-x'
                              : 'circle-arrow-right'
                        )}
                        className={
                          categorizedAttributeKey === FULFILLED_KEY
                            ? 'category-fulfilled'
                            : 'text-info-neutral'
                        }
                      />
                    </Col>
                    <Col className="d-flex justify-content-top">{name}</Col>
                  </Row>
                ))}
              </div>
            )
          )}

        <div>
          <h5 className="fw-bold">{DEFINITION_KEY}</h5>
          <p>{getValue(DEFINITION_KEY, criterion?.descriptions)}</p>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};
