/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRMediaSpinner, getIcon } from '@SLR/shared-library';
import { FC, useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from 'react-zoom-pan-pinch';

export type GalleryImageProps = {
  alt: string;
  url: string;
  disableZoom?: boolean;
  isActive?: boolean;
  className?: string;
};

export const GalleryImage: FC<GalleryImageProps> = ({
  alt,
  url,
  disableZoom = false,
  isActive = false,
  className = ''
}) => {
  const zoomView = useRef<ReactZoomPanPinchRef | null>(null);

  const [doubleClickMode, setDoubleClickMode] = useState<
    'zoomIn' | 'zoomOut' | 'reset'
  >('zoomIn');
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  /**
   * Reset zoom if image is zoomed in and current image is not the active/centered image in the carousel
   */
  useEffect(() => {
    if (!isActive && isZoomed && zoomView.current) {
      zoomView.current.resetTransform();
    }
  }, [isActive, isZoomed]);

  return (
    <TransformWrapper
      ref={zoomView}
      disabled={disableZoom}
      doubleClick={{ step: 2, mode: doubleClickMode }}
      onTransformed={(ref) => {
        const scale = ref.instance.transformState.scale;
        setIsZoomed(scale !== 1);
        setDoubleClickMode(scale > 1 ? 'reset' : 'zoomIn');
      }}
      panning={{
        disabled: !isZoomed
      }}
    >
      <TransformComponent wrapperClass="w-100" contentClass="w-100">
        <SLRMediaSpinner
          src={url}
          className={className}
          spinnerWrapperClassName="d-flex w-100 h-100"
          iconSize="3x"
          fallback={getIcon('fal', 'image-slash')}
        >
          <Image alt={alt} src={url} className={className} />
        </SLRMediaSpinner>
      </TransformComponent>
    </TransformWrapper>
  );
};
