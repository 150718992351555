/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { Breakpoint, hasValue, useWindowSize } from '@SLR/shared-library';
import { ErrorView, Paywall } from 'components';
import { useGetEntries } from 'hooks';
import { useAccess, useMarketplace } from 'providers';
import { FC, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EntryCreate, EntryItem, EntryLoader, FilterSort, Hero } from '.';

import './Marketplace.scss';

export const Marketplace: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace'
  });

  const { isManagementContext } = useMarketplace();

  const {
    data: entries,
    error: entriesError,
    isError: isEntriesError,
    isPending: isEntriesLoading
  } = useGetEntries();

  // #region Public Access
  const { isPublicAccess } = useAccess();
  const { breakpoint } = useWindowSize();

  const numberOfEntries = useMemo(
    () => entries?.length ?? 0,
    [entries?.length]
  );

  // Number of columns: xs={1} md={2} xl={3}
  const columnCount = useMemo(() => {
    if (breakpoint === Breakpoint.XS || breakpoint === Breakpoint.SM) return 1;
    else if (breakpoint === Breakpoint.MD || breakpoint === Breakpoint.LG)
      return 2;
    else return 3;
  }, [breakpoint]);

  /**
   * Calculate the max number of entry item based on breakpoint
   * Assumptions: Number of columns: xs={1} md={2} xl={3}, number of visible rows 3 and covered 2
   * -> (3 + 2) * (xs={1} md={2} xl={3})
   */
  const visibleEntryNumber = useMemo(
    () => (isPublicAccess ? (3 + 2) * columnCount : numberOfEntries),
    [isPublicAccess, columnCount, numberOfEntries]
  );

  /**
   * Calculate the starting index for the semi transparent entry item based on breakpoint
   * Assumptions: Number of columns: xs={1} md={2} xl={3}, number of visible rows 3 and covered 2
   * Note: index starts at 0 -> 3 * (xs={1} md={2} xl={3}) - 1
   */
  const semiTransparentEntryStartIndex = useMemo(
    () => 3 * columnCount - 1,
    [columnCount]
  );

  const paywallTopMargin = useMemo(() => {
    const hasEnoughEntries = numberOfEntries >= visibleEntryNumber;
    const numberOfSemiTransparentEntries =
      numberOfEntries - (semiTransparentEntryStartIndex + 1);

    // Cover entry loader (only one row) when entries are being fetched
    if (isEntriesLoading) return '-24rem';
    // Add small margin when there are no entries or no semi transparent entries
    else if (numberOfEntries === 0 || numberOfSemiTransparentEntries <= 0)
      return '2rem';
    // Cover two rows when there are enough entries
    else if (hasEnoughEntries) return '-52rem';
    /**
     * Cover one or two rows when there are enough entries but not enough semi transparent entries
     * If the number of semi transparent entries is smaller than the column count then there is only one row otherwise two
     */ else
      return `-${(numberOfSemiTransparentEntries > columnCount ? 2 : 1) * 24}rem`;
  }, [
    columnCount,
    isEntriesLoading,
    numberOfEntries,
    semiTransparentEntryStartIndex,
    visibleEntryNumber
  ]);

  // #endregion

  if (isEntriesError) return <ErrorView error={entriesError} />;

  return (
    <>
      <Hero />
      {!isPublicAccess && <FilterSort />}
      <Container className="marketplace">
        <Row xs={1} md={2} xl={3} className="pt-4">
          {isManagementContext && <EntryCreate />}
          {isEntriesLoading ? (
            <EntryLoader />
          ) : hasValue(entries) ? (
            entries
              ?.slice(0, visibleEntryNumber)
              ?.map((entry, index) => (
                <EntryItem
                  key={entry.id}
                  entry={entry}
                  className={
                    isPublicAccess && index > semiTransparentEntryStartIndex
                      ? 'semi-transparent'
                      : ''
                  }
                />
              ))
          ) : (
            !isManagementContext &&
            !isPublicAccess && (
              <Col xs="12" className="py-3 marketplace-noEntries">
                {t('noEntries')}
              </Col>
            )
          )}
        </Row>
        {isPublicAccess && (
          <Paywall
            style={{
              marginTop: paywallTopMargin
            }}
          />
        )}
      </Container>
    </>
  );
};
