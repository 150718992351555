/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRAuthConfiguration, SLRSpinner } from '@SLR/shared-library';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { isProd } from './env';

export const APP_URL = import.meta.env.VITE_URL as string;
export const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL as string;
export const KEYCLOAK_REALM = import.meta.env.VITE_KEYCLOAK_REALM as string;
export const KEYCLOAK_CLIENT_ID = import.meta.env
  .VITE_KEYCLOAK_CLIENT_ID as string;

export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
  pkceMethod: 'S256',
  enableLogging: !isProd,
  responseMode: 'fragment',
  redirectUri: window.location.href
};

export const keycloak = new Keycloak({
  url: `${KEYCLOAK_URL}/`,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID
});

export const authConfiguration: SLRAuthConfiguration = {
  clientId: KEYCLOAK_CLIENT_ID,
  defaultRedirectUrl: APP_URL,
  keycloak,
  keycloakInitOptions,
  spinner: <SLRSpinner />
};
