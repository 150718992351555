/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  CreateEntryRequest,
  DeleteEntryRequest,
  GetEntriesByOrganizationRequest,
  GetEntryByOrganizationRequest,
  HTTPRequestInit,
  ManagementEntriesApi,
  PublishEntryRequest,
  RequestOpts,
  SubmitEntryRequest,
  UnpublishEntryRequest,
  UpdateEntryRequest
} from '@SLR/marketplaceService-sdk';
import { handleError } from '@SLR/shared-library';
import { useCallback, useMemo } from 'react';
import { useGetMarketplaceServiceApiConfiguration } from './marketplace.service';

/**
 * Forces the request to ignore "readonly" for ids of faq and testimonial and keep them in the request
 */
const forceKeepArrayObjectId =
  (requestParameters: UpdateEntryRequest) =>
  async (requestContext: { init: HTTPRequestInit; context: RequestOpts }) => {
    const { init } = await requestContext;
    // force entry update to keep testimonial and faq ids as typescript sdk will omit them because readonly
    init.body = requestParameters.entryDto;
    return init;
  };

export const useManagementEntriesApi = () => {
  const configuration = useGetMarketplaceServiceApiConfiguration();
  const apiInstance = useMemo(
    () => new ManagementEntriesApi(configuration),
    [configuration]
  );

  const getEntryByOrganization = useCallback(
    (requestParameters: GetEntryByOrganizationRequest) =>
      apiInstance.getEntryByOrganization(requestParameters).catch(handleError),
    [apiInstance]
  );

  const getEntriesByOrganization = useCallback(
    (requestParameters: GetEntriesByOrganizationRequest) =>
      apiInstance
        .getEntriesByOrganization(requestParameters)
        .catch(handleError),
    [apiInstance]
  );

  const createEntry = useCallback(
    (requestParameters: CreateEntryRequest) =>
      apiInstance.createEntry(requestParameters).catch(handleError),
    [apiInstance]
  );

  const updateEntry = useCallback(
    (requestParameters: UpdateEntryRequest) =>
      apiInstance
        .updateEntry(
          requestParameters,
          forceKeepArrayObjectId(requestParameters)
        )
        .catch(handleError),
    [apiInstance]
  );

  const deleteEntry = useCallback(
    (requestParameters: DeleteEntryRequest) =>
      apiInstance.deleteEntry(requestParameters).catch(handleError),
    [apiInstance]
  );

  const submitEntry = useCallback(
    (requestParameters: SubmitEntryRequest) =>
      apiInstance.submitEntry(requestParameters).catch(handleError),
    [apiInstance]
  );

  const publishEntry = useCallback(
    (requestParameters: PublishEntryRequest) =>
      apiInstance.publishEntry(requestParameters).catch(handleError),
    [apiInstance]
  );

  const unPublishEntry = useCallback(
    (requestParameters: UnpublishEntryRequest) =>
      apiInstance.unpublishEntry(requestParameters).catch(handleError),
    [apiInstance]
  );

  return {
    getEntryByOrganization,
    getEntriesByOrganization,
    createEntry,
    updateEntry,
    deleteEntry,
    submitEntry,
    publishEntry,
    unPublishEntry
  };
};
