/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { toDayMonthYear } from '@SLR/shared-library';
import { useGetEntry } from 'hooks';
import { orderBy } from 'lodash';
import { useAccess } from 'providers';
import { FC } from 'react';
import { Accordion, Badge, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QualityCriterion } from '.';

import './AiRatings.scss';

export const AiRating: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.ratings.ai'
  });
  const { isProdAccess } = useAccess();

  const { entryId } = useParams();
  const { data: entry } = useGetEntry(entryId);

  const qualityEvaluations = entry?.qualityEvaluation;

  return (
    !isProdAccess &&
    Boolean(qualityEvaluations) && (
      <Row className="gap-4 justify-content-between ai-rating">
        <Col xs="12" lg="5">
          <Row className="gap-3">
            <Col xs="12">
              <h3 className="d-flex gap-3 align-items-center">
                <span>{t('title')}</span>
                <Badge pill bg="danger" className="status-badge">
                  {t('status')}
                </Badge>
              </h3>
            </Col>
            <Col xs="12">
              <Trans t={t} i18nKey="description" />
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="6">
          <Row>
            <Col xs="12">
              <Accordion defaultActiveKey={null} className="criteria-accordion">
                {orderBy(
                  qualityEvaluations?.qualityCriteria,
                  ['criteriaOrder'],
                  'asc'
                ).map((criterion) => (
                  <QualityCriterion
                    key={criterion.criteriaOrder.toString()}
                    isRated={criterion.rated}
                    criterion={criterion}
                  />
                ))}
              </Accordion>
            </Col>
            <Col xs="12" className="text-center py-3 fs-5 updated">
              {t('updated', {
                date: toDayMonthYear(qualityEvaluations?.updated)
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  );
};
