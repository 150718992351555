/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRFormInfo, SLRTextEditor } from '@SLR/shared-library';
import { FC, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EntryInput,
  PRICE_INFORMATION_MAX_CHAR_LENGTH,
  PRICE_LONG_DESCRIPTION_MAX_CHAR_LENGTH
} from '../../..';
import { PriceDropdownForm } from './price-dropdown/PriceDropdownForm';

export const PriceForm: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.priceAndContact.priceInformation'
  });

  const {
    formState: { errors },
    control,
    watch,
    register
  } = useFormContext<EntryInput>();

  const priceInformationCount = watch('priceInformation')?.length ?? 0;

  const options: string[] = useMemo(
    () => Object.values(t('options', { returnObjects: true })),
    [t]
  );

  const priceInformation = watch('priceInformation');
  const isOwnDescription = !(
    priceInformation === null ||
    priceInformation === undefined ||
    priceInformation === t('options.onRequest') ||
    priceInformation === t('options.free')
  );

  return (
    <>
      <Form.Label
        htmlFor="priceInformation"
        className={errors.priceInformation ? 'text-danger' : ''}
      >
        {t('title')}
      </Form.Label>
      <SLRFormInfo text={t('info')} />
      <PriceDropdownForm
        id="priceInformation"
        className="mt-3"
        control={control}
        name="priceInformation"
        isInvalid={!isOwnDescription && !!errors?.priceInformation}
        options={options}
      />

      {isOwnDescription ? (
        <Row className="gap-3">
          <Col xs="12">
            <Form.Control
              id="priceInformation"
              type="text"
              as="textarea"
              rows={2}
              placeholder={t('ownDescription.placeholder')}
              maxLength={PRICE_INFORMATION_MAX_CHAR_LENGTH}
              className="form-control-lg mt-3"
              isInvalid={!!errors?.priceInformation}
              {...register('priceInformation')}
            />

            <SLRFormInfo
              isInvalid={!!errors?.priceInformation}
              text={errors?.priceInformation?.message}
              counter={{
                count: priceInformationCount,
                maxCount: PRICE_INFORMATION_MAX_CHAR_LENGTH
              }}
            />
          </Col>
          <Col xs="12">
            <Form.Label htmlFor="longPriceInformation">
              {t('longPriceInformation.title')}
            </Form.Label>
            <SLRFormInfo text={t('longPriceInformation.info')} />
            <SLRTextEditor
              id="longPriceInformation"
              control={control}
              name="longPriceInformation"
              rows={12}
              maxLength={PRICE_LONG_DESCRIPTION_MAX_CHAR_LENGTH}
              className="form-control-lg mt-3"
              isInvalid={!!errors?.longPriceInformation}
              errorMessage={errors?.longPriceInformation?.message}
            />
          </Col>
        </Row>
      ) : (
        <SLRFormInfo
          isInvalid={!!errors?.priceInformation}
          text={errors?.priceInformation?.message}
        />
      )}
    </>
  );
};
