/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { ExperienceReportDto } from '@SLR/marketplaceService-sdk';
import { SLRModal } from '@SLR/shared-library';
import { FC, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReportForm } from '..';

import './ReportModal.scss';

export type ReportModalProps = {
  entryId?: string | null;
  entryName?: string | null;
  organizationName?: string;
  report: ExperienceReportDto;
  onClose: () => void;
};

export const ReportModal: FC<ReportModalProps> = ({
  entryId,
  entryName,
  organizationName,
  report,
  onClose
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.ratings.experience.form.edit'
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    onClose();
    setIsLoading(false);
  }, [onClose]);

  return (
    <SLRModal
      isOpen
      onClose={handleClose}
      onHide={handleClose}
      isLoading={isLoading}
      backdrop="static"
      title={organizationName}
      size="xl"
      fullscreen="md-down"
      className="report-modal"
    >
      <div className="p-0 p-4 pb-sm-0">
        <Row className="gap-3">
          <Col xs="12">
            <h2>{t('title')}</h2>
          </Col>
          <Col xs="12">
            {t('description', {
              solution: entryName,
              organization: organizationName
            })}
            <Col className="pt-4">
              <ReportForm
                entryId={entryId}
                entryName={entryName}
                report={report}
                onSuccess={handleClose}
                onLoading={setIsLoading}
                onCancel={handleClose}
              />
            </Col>
          </Col>
        </Row>
      </div>
    </SLRModal>
  );
};
