/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRPageHead } from '@SLR/shared-library';
import { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiRating, ExperienceReport } from '.';

import './Ratings.scss';

export const Ratings: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.ratings'
  });

  return (
    <Container fluid className="ratings">
      <Container>
        <SLRPageHead
          labels={{
            title: t('title')
          }}
          className="pb-4 head"
        />
        <div className="d-flex flex-column gap-5">
          <AiRating />
          <ExperienceReport />
        </div>
      </Container>
    </Container>
  );
};
