/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExperienceReportDtoRecommendationEnum } from '@SLR/marketplaceService-sdk';
import { getIcon } from '@SLR/shared-library';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './LikeForm.scss';

export type LikeFormProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  entryName?: string | null;
  isEdit?: boolean;
};

export const LikeForm = <T extends FieldValues>({
  control,
  name,
  entryName,
  isEdit
}: LikeFormProps<T>) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.ratings.experience.form'
  });

  const {
    field: { value, onChange }
  } = useController({
    name,
    control
  });

  return (
    <fieldset className="like-form">
      <legend className="form-label">
        {t('recommendation', { solution: entryName })}
      </legend>
      <ToggleButtonGroup
        className="gap-3"
        type="radio"
        value={value}
        onChange={onChange}
        name={name}
      >
        <ToggleButton
          className={`p-3 toggle ${isEdit ? 'edit' : ''}`}
          variant="link"
          id={ExperienceReportDtoRecommendationEnum.ThumbsUp}
          value={ExperienceReportDtoRecommendationEnum.ThumbsUp}
          aria-label={ExperienceReportDtoRecommendationEnum.ThumbsUp}
        >
          <FontAwesomeIcon icon={getIcon('fas', 'thumbs-up')} />
        </ToggleButton>
        <ToggleButton
          className={`px-4 toggle ${isEdit ? 'edit' : ''}`}
          variant="link"
          id={ExperienceReportDtoRecommendationEnum.NotSpecified}
          value={ExperienceReportDtoRecommendationEnum.NotSpecified}
          aria-label={ExperienceReportDtoRecommendationEnum.NotSpecified}
        >
          <span className="line-1 not-specified">
            {t(ExperienceReportDtoRecommendationEnum.NotSpecified)}
          </span>
        </ToggleButton>
        <ToggleButton
          className={`p-3 toggle ${isEdit ? 'edit' : ''}`}
          variant="link"
          id={ExperienceReportDtoRecommendationEnum.ThumbsDown}
          value={ExperienceReportDtoRecommendationEnum.ThumbsDown}
          aria-label={ExperienceReportDtoRecommendationEnum.ThumbsDown}
        >
          <FontAwesomeIcon icon={getIcon('fas', 'thumbs-down')} />
        </ToggleButton>
      </ToggleButtonGroup>
    </fieldset>
  );
};
